<template>
  <Skeleton v-if="isLoading" class="p-6" />
  <div v-else>
    <p class="averta-bold is-size-5 mb-6" v-if="currentPath === 'complete-offering'">Lengkapi Penawaran Dari Konsultan</p>
    <p class="averta-bold is-size-5 mb-6" v-if="currentPath === 'update-price'">Ubah Harga Pada Penawaran</p>
    <div class="columns">
      <div class="column is-3 border-right">
        <span class="is-size-5 averta-bold">Pilih Area</span>
        <div class="area-wrapper mt-4">
          <b-button v-for="buttonArea in dataLengkapiPenawaran" :key="buttonArea.area_name"
            :class="{ 'is-active': area === buttonArea.area_name }" @click="changeIsActive(buttonArea.area_name)"
            class="btn-tabs mb-5">
            <div class="white-space-normal is-flex flex-direction is-flex-direction-column">
              <p class="averta-bold">{{ buttonArea.area_name }}</p>
            </div>
          </b-button>
        </div>
      </div>
      <div class="column is-9">
        <div v-for="(dataArea, indexArea) in dataLengkapiPenawaran" :key="dataArea.area_name">
          <b-tabs v-if="area === dataArea.area_name">
            <b-tab-item v-for="(dataComponents, indexComp) in dataArea.components" :key="dataComponents.component_name">
              <template #header>
                <div>
                  <span>{{ dataComponents.component_name }}</span>
                </div>
              </template>
              <div
                v-for="(dataDetailComponent, indexDetail) in dataComponents.detail_components"
                :key="dataDetailComponent.detail_component_name"
              >
                <p class="averta-bold mb-2">
                  &#8226;
                  {{
                    dataDetailComponent.detail_component_name ?
                    dataDetailComponent.detail_component_name.toUpperCase() :
                    dataDetailComponent.detail_component.toUpperCase()
                  }}
                </p>
                <div class="bg-grey notification has-background-white-ter is-flex py-2 mb-5">
                  <p>
                    <span class="has-text-grey">Catatan : </span> {{ dataDetailComponent.note }}
                  </p>
                </div>
                <div v-for="(dataItem, indexDataItem) in dataDetailComponent.item" :key="dataItem.id" class="wrapper-body-tab p-4 mb-4">
                  <FormUnitPrice
                    v-if="isProjectUnitPrice && dataItem.note_item_id"
                    :dataItem="dataItem"
                    @updateUnitPrice="($event) => updateUnitPrice($event, indexArea, indexComp, indexDetail, indexDataItem, dataItem)"
                  />
                  <div v-else class="is-flex is-justify-content-space-between mb-4">
                    <div>
                      <p class="has-text-grey">Item Pekerjaan</p>
                      <p class="averta-bold note-item-working-wrapper ellipsis-3">{{ dataItem.note_item_working }}</p>
                    </div>
                    <div class="is-flex">
                      <div class="is-flex wrapper-volume mr-2">
                        <div class="section-volume">
                          <p class="has-text-grey mb-2 white-space-nowrap">Volume Area Pengerjaan</p>
                          <p class="averta-bold">{{ dataItem.volume }}</p>
                        </div>
                        <div class="section-satuan">
                          <p class="has-text-grey mb-2">Satuan</p>
                          <p class="averta-bold">{{ dataItem.unit_name }}</p>
                        </div>
                      </div>
                      <!-- <div class="is-clickable" @click="deleteItemPekerjaan(dataItem.id, dataDetailComponent.item.length)"><img
                          :src="require('@/assets/icons/button-delete.svg')" alt=""></div> -->
                    </div>
                  </div>

                  <div class="has-background-white p-3 wrapper-tukang">
                    <p class="averta-bold mb-4">Daftar Keahlian Tukang</p>
                    <div v-for="dataWorker in dataItem.workers" :key="dataWorker.skill_id"
                      class="mb-5 wrapper-item-tukang">
                      <div class="columns">
                        <div class="column">
                          <p class="has-text-grey mb-2">Keahlian Tukang</p>
                          <div class="is-flex is-align-items-center">
                            <img :src="dataWorker.skill_image" :alt="dataWorker.skill_name" class="skill-img">
                            <p class="averta-bold">{{ dataWorker.skill_name }}</p>
                          </div>
                        </div>
                        <div class="column">
                          <p class="has-text-grey mb-2">Maksimum Hari Kerja</p>
                          <p class="averta-bold">{{ dataWorker.max_day }}</p>
                        </div>
                        <div class="column">
                          <p class="has-text-grey mb-2">Jumlah Tukang</p>
                          <p class="averta-bold">{{ dataWorker.total_worker }}</p>
                        </div>
                        <div class="column" v-if="!isProjectUnitPrice || !dataItem.note_item_id">
                          <p class="has-text-grey mb-2">Harga Tukang/Hari <span class="has-text-danger">*</span></p>
                          <div class="is-flex">
                            <span>Rp </span>
                            <input
                              type="text"
                              class="form-control averta-bold ml-2"
                              v-model="dataWorker.unit_price"
                              @input="formatPrice(dataWorker)"
                              :placeholder="dataWorker.unit_price === null && '0'"
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="divider"></div>
                  <div class="has-background-white p-3 wrapper-material">
                    <p class="averta-bold mb-4">Daftar Material</p>
                    <div v-for="dataMaterial in dataItem.materials" :key="dataMaterial.product_id"
                      class="mb-5 wrapper-item-material">
                      <div class="columns">
                        <div class="column">
                          <p class="has-text-grey mb-2">Nama Material</p>
                          <p class="averta-bold mb-3">{{ dataMaterial.product_name }} <span
                              v-if="dataMaterial.is_hide === true" class="text-hidden">Disembunyikan</span></p>
                          <p v-if="dataMaterial.is_custom === true" class="text-custom">Material Custom</p>
                        </div>
                        <div class="column">
                          <p class="has-text-grey">Jumlah Dibutuhkan</p>
                          <p class="averta-bold">{{ `${dataMaterial.total_need} ${dataMaterial.uom_name}` }}</p>
                        </div>
                        <div class="column">
                          <p class="has-text-grey">Jumlah Dipenawaran</p>
                          <p class="averta-bold">{{ `${dataMaterial.total_offering} ${dataMaterial.uom_name}` }}</p>
                        </div>
                        <div class="column" v-if="!isProjectUnitPrice || !dataItem.note_item_id">
                          <p class="has-text-grey mb-2">Harga Satuan <span class="has-text-danger">*</span></p>
                          <div class="is-flex">
                            <span>Rp </span>
                            <input
                              type="text"
                              class="form-control averta-bold ml-2"
                              v-model="dataMaterial.unit_price"
                              @input="formatPrice(dataMaterial)"
                              :placeholder="dataMaterial.unit_price === null && '0'"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="note-material" v-if="dataMaterial.note">
                        <p class="has-text-grey mb-2">Catatan</p>
                        <p class="averta-bold">{{ dataMaterial.note }}</p>
                      </div>
                      <!-- <div v-if="dataMaterial.variants && dataMaterial.variants[0].length > 0" class="wrapper-variant">
                        <p class="averta-bold">Kebutuhan Spesifik</p>
                        <div class="divider my-3"></div>
                        <div v-for="dataVariant in dataMaterial.variants" :key="dataVariant">
                          <div class="wrapper-item-variant mb-3">
                            <div class="columns">
                              <div class="column" v-for="dataDetailVariant in dataVariant" :key="dataDetailVariant.id">
                                <p class="text-grey">{{ dataDetailVariant.name }}</p>
                                <p class="averta-bold">{{ dataDetailVariant.value || dataDetailVariant.value_variant }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </b-tab-item>
          </b-tabs>
        </div>
      </div>
    </div>

    <b-modal v-model="modalValidation" width="28vw" :can-cancel="false">
      <div class="modal-validation is-flex is-justify-content-center">
        <div class="has-text-centered">
          <img :src="require('@/assets/img/diskon.png')" alt="" class="my-5" width="250">
          <p class="averta-bold is-size-5 mb-5">Tidak Bisa Dihapus</p>
          <p>Setiap item komponen minimal harus memiliki 1 item pekerjaan</p>
          <div class="wrapper-btn-logout is-flex is-justify-content-space-between mt-5">
            <button class="btn-tutup" @click="modalValidation = false" >Tutup</button>
          </div>
        </div>
      </div>
    </b-modal>

  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { formatCurrency } from '@/helpers'
import Skeleton from '@/components/skeleton/Skeleton'
import discardImage from '@/assets/img/delete-area-component.png'
import FormUnitPrice from '@/components/form/FormUnitPrice'

export default {
  name: 'CompleteOffering',
  components: {
    Skeleton,
    FormUnitPrice
  },
  props: {
    isFilled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      dataOffering: 'project/getDataDetailPenawaran'
    }),
    currentPath () {
      const path = this.$route.path.split('/')
      const lastElement = path[path.length - 1]

      return lastElement
    }
  },
  data () {
    return {
      isLoading: false,
      dataLengkapiPenawaran: null,
      area: null,
      modalValidation: false,
      isProjectUnitPrice: false
    }
  },
  methods: {
    changeIsActive (area) {
      this.area = area
    },
    postLengkapiPenawaran (response, type) {
      const res = response.data.data

      if (Array.isArray(res)) {
        this.dataLengkapiPenawaran = res
      } else {
        this.dataLengkapiPenawaran = res.data
      }

      // this.dataLengkapiPenawaran = res.data
      this.area = this.dataLengkapiPenawaran[0].area_name
      this.isProjectUnitPrice = res.is_use_unit_price || (this.dataOffering && this.dataOffering.vendor && this.dataOffering.vendor.is_use_unit_price)

      this.dataLengkapiPenawaran.forEach(area => {
        area.components.forEach(components => {
          components.detail_components.forEach(detailComponent => {
            detailComponent.item.forEach(item => {
              item.workers.forEach(worker => {
                if (type === 'update') {
                  this.formatPrice(worker)
                } else if (worker.unit_price > 0 || worker.unit_price !== '' || worker.unit_price !== null) {
                  this.formatPrice(worker)
                }
              })
              item.materials.forEach(material => {
                if (type === 'update') {
                  this.formatPrice(material)
                } else if (material.unit_price > 0 || material.unit_price !== '' || material.unit_price !== null) {
                  this.formatPrice(material)
                }
              })
            })
          })
        })
      })

      this.$emit('postLengkapiPenawaran', { data: this.dataLengkapiPenawaran, is_use_unit_price: this.isProjectUnitPrice })
    },
    getLengkapiPenawaran () {
      this.isLoading = true
      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId
      }

      if (this.isFilled) {
        payload.is_filled = this.isFilled ? 1 : 0
      }

      this.$store
        .dispatch('project/getLengkapiPenawaran', payload)
        .then(response => {
          this.postLengkapiPenawaran(response)
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat data detail harga, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    getUpdateHargaPenawaran () {
      this.isLoading = true
      const payload = {
        url: this.url,
        offering_id: this.$route.params.offeringId
      }

      this.$store
        .dispatch('project/getUpdateHargaPenawaranV2', payload)
        .then(response => {
          this.postLengkapiPenawaran(response, 'update')
          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          console.log(error)
          this.$buefy.toast.open({
            message: '<p class="averta-regular">Gagal memuat data detail harga, silahkan coba lagi</p>',
            type: 'is-danger'
          })
        })
    },
    addPrice () {
      this.$emit('postLengkapiPenawaran', this.dataLengkapiPenawaran)
    },
    formatPrice (data) {
      if (data.unit_price !== null) {
        data.unit_price = data.unit_price.toString().replace(/\D/g, '')
        const formattedPrice = Number(data.unit_price).toLocaleString('en-US')
        data.unit_price = formattedPrice
        this.$emit('postLengkapiPenawaran', this.dataLengkapiPenawaran)
      }
    },
    formatThousand (x) {
      return formatCurrency(x)
    },
    deleteItemPekerjaan (idItemComponent, isItemJustOne) {
      if (isItemJustOne <= 1) {
        this.modalValidation = true
      } else {
        this.$swal({
          imageUrl: discardImage,
          imageAlt: 'cancel offering',
          width: '600px',
          padding: '10px 10px 30px 10px',
          title: '<p class="averta-bold label-22">Hapus Item Pekerjaan Ini?</p>',
          html: '<p>Seluruh informasi pada item pekerjaan ini akan terhapus.</p>',
          showCancelButton: true,
          confirmButtonText: 'Hapus',
          cancelButtonText: 'Batalkan',
          customClass: {
            confirmButton: 'btn-hapus-detail-component',
            cancelButton: 'btn-batal-detail-component'
          }
        }).then((result) => {
          if (result.isConfirmed) {
            // Aksi di hapus
            const payload = {
              url: this.url,
              item_component_id: idItemComponent
            }
            this.$store
              .dispatch('project/deleteDetailComponent', payload)
              .then(response => {
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Berhasil menghapus data item pekerjaan</p>',
                  type: 'is-success'
                })
                if (this.currentPath === 'update-price') {
                  this.getUpdateHargaPenawaran()
                } else {
                  this.getLengkapiPenawaran()
                }
              })
              .catch(error => {
                console.log(error)
                this.$buefy.toast.open({
                  message: '<p class="averta-regular">Gagal menghapus data item pekerjaan, silahkan coba lagi</p>',
                  type: 'is-danger'
                })
                if (this.currentPath === 'update-price') {
                  this.getUpdateHargaPenawaran()
                } else {
                  this.getLengkapiPenawaran()
                }
              })
          } else {
            // Aksi tidak dihapus
          }
        })
      }
    },
    updateUnitPrice (data, indexArea, indexComp, indexDetail, indexDataItem, item) {
      const convertedPrices = Object.fromEntries(
        Object.entries(data).map(([key, value]) => [
          key,
          typeof value === 'string' ? Number(value.replace(/\./g, '').replace(',', '.')) : Number(value)
        ])
      )

      this.dataLengkapiPenawaran[indexArea].components[indexComp].detail_components[indexDetail].item[indexDataItem] = {
        ...item,
        ...convertedPrices
      }
    }
  },
  created () {
    const path = this.$route.path.split('/')
    const lastElement = path[path.length - 1]

    if (lastElement === 'update-price') {
      this.getUpdateHargaPenawaran()
    } else {
      this.getLengkapiPenawaran()
    }
  }
}
</script>

<style scoped>
.area-wrapper {
  display: flex;
  flex-direction: column;
}

.white-space-normal {
  white-space: normal;
}

.btn-tabs {
  height: auto;
  border-radius: 100px;
  color: #9a9a9a;
  border: 1px solid #e1e1e1;
}

.btn-tabs.is-active {
  background: rgba(49, 133, 252, 0.1);
  color: #3185fc;
  border: 1px solid #3185fc;
}

.border-right {
  border-right: 1px solid #e1e1e1;
}

.wrapper-body-tab {
  background: #FAFAFA;
}

.wrapper-volume {
  background: #ffffff;
  padding: 12px 24px;
  border: 1px solid #E1E1E1;
  border-radius: 10px;
}

.section-volume {
  padding-right: 14px;
  flex: 1;
  width: 15em;
}

.section-satuan {
  border-left: 2px solid #E1E1E1;
  padding-left: 14px;
  flex: 1;
}

.wrapper-tukang,
.wrapper-material {
  border-radius: 10px;
}

.wrapper-item-tukang:not(:last-child),
.wrapper-item-material:not(:last-child),
.wrapper-item-variant:not(:last-child) {
  padding-bottom: 24px;
  border-bottom: 1px solid #E1E1E1;
}

.skill-img {
  width: 34px;
  margin-right: 10px;
}

.divider {
  border-top: 2px dashed #E1E1E1;
  margin: 32px 0px;
}

.text-custom {
  background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.24%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  background-clip: text;
  font-style: italic;
}

.text-hidden {
  background: #3185FC;
  font-family: 'Averta';
  font-style: italic;
  color: white;
  padding: 4px 14px;
  border-radius: 14px;
}

.form-control {
  border-radius: 4px;
  border: 1px #E1E1E1 solid;
  padding: 6px 12px;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.note-material {
  border: 1px solid #E1E1E1;
  border-radius: 12px;
  padding: 10px;
}

.modal-validation {
  background: white;
  border-radius: 22px;
  padding: 32px;
}

.btn-tutup {
  background: white;
  border-radius: 100px;
  border: 1px solid #D9272D;
  color: #D9272D;
  width: 100%;
  margin-right: 10px;
  padding: 12px;
  font-family: 'Averta-Bold';
  cursor: pointer;
}

.note-item-working-wrapper {
  width: 90%;
}

@media only screen and (max-width: 600px) {
  .note-item-working-wrapper {
    width: 100%;
  }
}

</style>
