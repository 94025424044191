<template>
  <div class="c-w-shell min-h-80vh is-flex">
    <div class="sidebar-monitoring" :class="{ 'minimize-sidebar': minimize }">
      <div class="flex-center-vertical is-justify-content-space-between p-4">
        <div class="flex-center is-clickable">
          <ArrowLeftIcon color="#868686" />
          <p class="averta-bold color-grey ml-2 label-16">Kembali</p>
        </div>
        <img
          class="is-clickable minimize-icon"
          :src="require('@/assets/icons/minimize-icon-used.svg')"
          alt=""
          @click="handleMinimizeSidebar"
        />
      </div>

      <div class="v-calendar-wrapper-normal border-bottom-1 p-2">
        <v-date-picker v-model="selectedDate" :from-page="fromPage" is-expanded color="red" />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Cari Kata Kunci</p>
        <b-input
          placeholder="ID Survey"
          type="search"
          icon="magnify"
          class="search-left-monitoring"
          v-model="search"
          @keyup.native.enter="getReschedule"
        >
        </b-input>
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Konsultan Survey</p>
        <b-autocomplete
          v-model="konsultan"
          placeholder="Semua Konsultan Survey"
          class="search-left-monitoring"
          :open-on-focus="true"
          :data="filteredKonsultan"
          field="name"
          @select="(option) => (selectedKonsultan = option)"
          :clearable="true"
        />
      </div>

      <div class="p-3 border-bottom-1">
        <p class="averta-bold white-space-nowrap">Filter Client</p>
        <b-autocomplete
          v-model="client"
          placeholder="Semua Client"
          class="search-left-monitoring"
          :open-on-focus="true"
          :data="filteredClient"
          field="name"
          @select="(option) => (selectedClient = option)"
          :clearable="true"
        />
      </div>
    </div>
    <div class="body-monitoring" ref="mainBody" :class="{ 'filled-monitoring': minimize }">
      <div class="header-current-date">
        <div class="flex-center">
          <div class="date-minimize-icon flex-center" :class="{ 'expand-minimize-icon': minimize }">
            <img
              class="is-clickable"
              :src="require('@/assets/icons/minimize-icon-used.svg')"
              alt=""
              @click="handleMinimizeSidebar"
            />
          </div>
          <p class="averta-black label-20">{{ formatSelectedDate }}</p>
        </div>
        <div class="flex-center">
          <div class="previous-next-arrow mr-1 flex-center" @click="() => handlePrevNext('prev')">
            <img :src="require('@/assets/icons/arrow-back-used.svg')" alt="" />
          </div>
          <div class="previous-next-arrow flex-center" @click="() => handlePrevNext('next')">
            <img :src="require('@/assets/icons/arrow-forward-used.svg')" alt="" />
          </div>
        </div>
      </div>

      <div
        class="collapse-sesi-custom-wrapper"
        :class="{ 'minimize-sidebar': minimize, 'removed-wrapper-sesi-custom': sesiCustom.length === 1 && sesiCustom[0].removed }"
        v-if="sesiCustom.length > 0"
      >
        <b-collapse
          class="collapse-sesi-custom"
          animation="slide"
          v-model="openCollapse"
          aria-id="sesi-custom"
        >
          <template #trigger="props">
            <div
              class="flex-center-vertical is-justify-content-space-between c-w-100"
              aria-controls="sesi-custom"
              :aria-expanded="props.open"
            >
              <div class="flex-center">
                <p class="averta-black label-15">
                  {{ sesiCustom.length }} Survey Custom
                  <span class="averta-reguler label-14">yang belum diatur jadwalnya</span>
                </p>
              </div>
              <ButtonApp :isSecondary="true" :isTransparent="true" :isBorderWhite="true">
                <div class="is-flex is-align-items-center py-1">
                  <p class="averta-bold mr-2 color-white">
                    {{ openCollapse ? 'Tutup Daftar' : 'Lihat Daftar' }}
                  </p>
                  <div class="chevron-sesi-custom" :class="{ open: !openCollapse }">
                    <ChevronIcon color="white" />
                  </div>
                </div>
              </ButtonApp>
            </div>
          </template>
          <div>
            <div class="svg-border">
              <svg width="100%" height="10">
                <line x1="0" y1="5" x2="100%" y2="5" stroke="white" stroke-width="2" stroke-dasharray="10,5" />
              </svg>
            </div>
            <div class="body-sesi-custom color-black">
              <p class="averta-bold mb-2">Daftar Survey</p>
              <div class="list-survey-custom-wrapper">
                <div
                  v-for="(item, index) in sesiCustom"
                  :key="`sesi-custom=${index}`"
                  class="list-sesi-custom"
                  :class="{'removed-sesi-custom': item.removed}"
                  :draggable="true"
                  @dragstart="onDragSesiCustomStart($event, item)"
                  @dragend="onDragSesiCustomEnd"
                >
                  <div class="flex-center-vertical info-vendor-sesi-custom">
                    <div class="vendor-img-sesi-custom flex-center">
                      <img :src="item.vendor.image_url" alt="" />
                    </div>
                    <p class="averta-bold color-red">
                      {{ item.vendor.id }} | {{ item.vendor.name }}
                      {{ item.vendor.no_unit ? '-' + item.vendor.no_unit : '' }}
                    </p>
                  </div>
                  <div class="address-sesi-custom">
                    <div>
                      <p class="color-grey">Alamat</p>
                      <p>{{ item.address }}</p>
                    </div>
                  </div>
                  <div class="drag-icon-sesi-custom flex-center">
                    <img
                      class="drag-icon"
                      :src="require('@/assets/icons/fluent-drag-black-used.svg')"
                      alt=""
                      style="pointer-events: none"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-collapse>
      </div>

      <div class="schedule-table" :class="{ 'loading-table': isLoadingTable }">
        <!-- Header -->
        <div
          class="header-table"
          :style="{
            top: positionStickyHeader
          }"
        >
          <div
            class="cell header-empty sticky-left"
            :style="{ height: `${HEIGHT_COLUMN}px` }"
          ></div>
          <div
            class="cell header"
            :style="{ height: `${HEIGHT_COLUMN}px` }"
            v-for="(item, index) in consultants"
            :key="`consultan=${index}`"
          >
            <div
              class="flex-center c-w-100 pr-1"
              :class="{ 'is-clickable': !item.name }"
              @click="() => handleSelectConsultant(item.name)"
            >
              <div class="consultan-img flex-center mr-2 flex-shrink-0">
                <img
                  :src="
                    item.image_url
                      ? item.image_url
                      : require('@/assets/icons/profile-picture-used.svg')
                  "
                  alt=""
                />
              </div>
              <div class="has-text-left ellipsis-content flex-grow-0">
                <p
                  class="label-14"
                  :class="[
                    item.name ? 'averta-black' : 'averta-reguler-italic',
                    { 'color-red': !item.name }
                  ]"
                >
                  {{ item.name ? item.name : 'Pilih Konsultan' }}
                </p>
                <p v-if="item.name" class="color-red averta-bold label-12">{{ item.id }}</p>
                <p v-if="item.vendor && item.vendor.name" class="label-12">
                  {{ item.vendor.name }}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div class="body">
          <div
            class="d-contents is-flex is-relative"
            v-for="(time, idxTime) in timeSurvey"
            :key="`time-${idxTime}`"
          >
            <div
              class="cell time"
              :style="{
                zIndex: 22,
                height: `${HEIGHT_COLUMN}px`
              }"
            >
              <div v-if="time !== 'empty'" class="time-survey flex-center">
                <p class="averta-black">{{ time }}</p>
                <div class="line"></div>
              </div>
            </div>
            <div
              v-for="(consultant, idxConsultant) in consultants"
              :key="`card-consultant-${idxConsultant}`"
              class="cell body is-relative"
              :style="{ height: `${HEIGHT_COLUMN}px` }"
              :data-timeslot="time"
              :data-consultant="consultant.id"
              @drop="onDrop($event, time, consultant)"
              @dragenter.prevent="onDragEnter($event, time, consultant.id)"
              @dragleave.prevent="onDragLeave($event)"
              @dragover.prevent
            >
              <div
                v-for="(item, idxCard) in getCardsForSlot(time, consultant.id)"
                :key="`card-events-${idxCard}`"
                class="card-events"
                :class="{
                  'card-sesi-custom': item.sesi === 'custom',
                  'cursor-move': cursorMove,
                  'z-index-300 border-white-1': idHoverCard === item.id && !isStretching && !modalActionConsultant,
                  'remove-card': tempCard && tempCard.removed && tempCard.id === item.id,
                  'transparent-card': consultantIdHoverCard === consultant.id && idHoverCard !== item.id && !isStretching
                }"
                :style="{
                  height: getCardStyle(item, idxTime),
                  backgroundColor:
                    (consultant.vendor && consultant.vendor.background) || item.vendor.background
                }"
                :draggable="cursorMove ? true : false"
                @dragstart="onDragStart($event, item, consultant)"
                @dragend="onDragEnd"
                @mousemove="checkIfReaching"
                @mouseleave="resetReaching"
              >
                <div class="drag-icon-wrapper">
                  <img
                    class="mb-3 drag-icon"
                    :src="require('@/assets/icons/fluent-drag-used.svg')"
                    alt=""
                    style="pointer-events: none"
                  />
                </div>
                <div
                  class="body-card-events"
                  @mouseover="() => handleHoverCard(item.id, consultant.id)"
                  @mouseleave="() => handleHoverCard(null)"
                >
                  <div class="flex-center is-flex-direction-column c-h-100 overflow-auto">
                    <div class="max-c-h-100 c-w-100 px-2 description-card">
                      <div v-if="item.vendor.image_url" class="vendor-card-img">
                        <img :src="item.vendor.image_url" alt="" />
                      </div>
                      <p>ID: {{ item.id }}</p>
                      <p class="averta-bold">{{ item.vendor.name }}</p>
                      <p>{{ item.sesi }} {{ item.start_time }} - {{ item.end_time }}</p>
                    </div>
                  </div>

                  <!-- TOOLTIP -->
                  <div
                    @mouseover="() => handleHoverCard(item.id, consultant.id)"
                    @mouseleave="() => handleHoverCard(null)"
                    class="tooltip-hover-card color-black"
                    :class="{ visible: idHoverCard === item.id && !isStretching }"
                  >
                    <div class="tooltip-header flex-center-vertical">
                      <div class="tooltip-vendor-img">
                        <img :src="item.vendor.image_url" alt="" />
                      </div>
                      <div class="ellipsis-content flex-grow-0">
                        <p class="averta-black label-16">
                          {{ item.vendor.id }} | {{ item.vendor.name }}
                        </p>
                        <p>{{ item.vendor.company }}</p>
                      </div>
                    </div>
                    <div class="p-4">
                      <div class="tooltip-date flex-center is-flex-direction-column">
                        <p class="color-blue-1 label-12">Tanggal & Waktu Survey</p>
                        <p class="averta-bold">{{ formatDate(item.date) }}</p>
                        <p class="color-grey">
                          ({{ item.sesi }}: {{ item.start_time }} - {{ item.end_time }})
                        </p>
                      </div>
                    </div>
                    <div class="is-flex px-4 pb-3">
                      <ButtonApp
                        width="100%"
                        :isSecondary="true"
                        class="mr-3"
                        @click="() => openDeleteConsultant(item, consultant)"
                      >
                        <p class="py-2 averta-bold">Hapus Konsultan</p>
                      </ButtonApp>
                      <ButtonApp
                        width="100%"
                        @click="() => handleDetailConsultant(item, consultant)"
                      >
                        <p class="py-2 averta-bold">Lihat Detail</p>
                      </ButtonApp>
                    </div>
                  </div>

                  <!-- EXPANDING CARD -->
                  <div
                    v-if="item.sesi === 'custom'"
                    class="expand-handle"
                    @mousedown="($event) => startStretching($event, item)"
                  ></div>
                </div>

              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Modal Action Consultant  -->
      <b-modal v-model="modalActionConsultant" @close="handleCancelDeleteConsultant">
        <div class="flex-center">
          <div class="modal-delete-consultant">
            <div class="flex-center is-flex-direction-column c-w-100 mb-5 has-text-centered">
              <img :src="require('@/assets/img/warning-1.png')" alt="" />
              <p class="averta-black color-red label-16 mt-3">{{ titleModalAction }}</p>
              <p :style="{ width: widthDescModalAction }">{{ descModalAction }}</p>
            </div>
            <div v-if="tempCard" class="bg-lightgray border-radius-12 p-3">
              <div class="border-bottom-dashed flex-center-vertical pb-3 mb-3">
                <div class="modal-vendor-icon flex-center mr-2">
                  <img :src="tempCard.vendor.image_url" alt="" />
                </div>
                <p class="averta-black color-red label-13">
                  {{ tempCard.vendor.id }} | {{ tempCard.vendor.name }}
                </p>
              </div>
              <div class="is-flex">
                <div class="flex-1 border-right-1-grey-13">
                  <p class="color-grey">Jadwal Survey</p>
                  <p class="averta-bold label-13">{{ formatDate(tempCard.date) }}</p>
                  <p class="label-12">
                    {{ tempCard.sesi }}: {{ tempCard.start_time }} - {{ tempCard.end_time }}
                  </p>
                </div>
                <div class="flex-1 ml-3">
                  <p class="color-grey">Konsultan Survey</p>
                  <div class="flex-center-vertical is-justify-content-space-between">
                    <div class="flex-center">
                      <div class="modal-consultant-img mr-2">
                        <img :src="tempCard.consultant.image_url ? tempCard.consultant.image_url : require('@/assets/icons/profile-picture-used.svg')" alt="" />
                      </div>
                      <div>
                        <p class="averta-bold label-13">{{ tempCard.consultant.name || '-' }}</p>
                        <p v-if="tempCard.consultant.name" class="color-red label-12">{{ tempCard.consultant.id }}</p>
                      </div>
                    </div>
                    <p class="label-12" v-if="tempCard.consultant.name">
                      {{ tempCard.consultant.vendor && tempCard.consultant.vendor.name }}
                    </p>
                  </div>
                </div>
              </div>

              <div class="is-flex mt-4">
                <ButtonApp
                  width="100%"
                  :isSecondary="true"
                  class="mr-3"
                  @click="handleCancelModalAction"
                >
                  <p class="py-2 averta-bold">Cek Lagi</p>
                </ButtonApp>
                <ButtonApp width="100%" @click="handleConfirmModalAction">
                  <p class="py-2 averta-bold">{{ btnModalAction }}</p>
                </ButtonApp>
              </div>
            </div>
          </div>
        </div>
      </b-modal>

      <ModalSelectConsultant
        :open="modalSelectConsultant"
        @closeModal="closeModalSelectConsultant"
        @selected="onSelectConsultant"
      />
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import { alertErrorMessage, toastMessage } from '@/helpers'
import { dataKonsultan, consultants, timeSurvey, cardSurvey, sesiCustom } from '../example-data.js'

export default {
  name: 'MonitoringKonsultan',
  components: {
    ArrowLeftIcon: () => import('@/components/icons/ArrowLeftIcon.vue'),
    ChevronIcon: () => import('@/components/icons/ChevronIcon.vue'),
    ButtonApp: () => import('@/components/button/ButtonApp.vue'),
    ModalSelectConsultant: () => import('../components/ModalSelectConsultant.vue')
  },
  data () {
    return {
      isLoadingClient: false,
      isLoadingKonsultan: false,
      isLoadingTable: false,

      minimize: false,
      selectedDate: new Date(),
      search: '',
      konsultan: '',
      client: '',

      cursorMove: false,
      idHoverCard: null,
      tempIdHoverCard: null,
      consultantIdHoverCard: null,
      tempConsultantIdHoverCard: null,
      fromPage: {
        year: parseInt(moment(new Date()).format('YYYY')),
        month: parseInt(moment(new Date()).format('M'))
      },

      modalActionConsultant: false,
      modalSelectConsultant: false,
      tempCard: null,
      dataFloating: null,
      openCollapse: false,

      selectedKonsultan: null,
      selectedClient: null,
      dataKonsultan: [],
      dataClient: [],

      timeSurvey: [],
      consultants: [],
      cardSurvey: [],
      sesiCustom: [],
      draggedEvent: null,
      tempDraggedEvent: null,
      typeModalAction: '',

      dataStretching: null,
      isStretching: false,
      startMouseY: 0,
      rowHeight: 80,
      cardHeight: 80,
      initialHeight: 80,

      HEIGHT_COLUMN: 80,
      WIDTH_COLUMN: 200
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    }),
    filteredKonsultan () {
      return this.dataKonsultan.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.konsultan.toLowerCase()) >= 0
      })
    },
    filteredClient () {
      return this.dataClient.filter((option) => {
        return option.name.toString().toLowerCase().indexOf(this.client.toLowerCase()) >= 0
      })
    },
    formatSelectedDate () {
      return moment(this.selectedDate).format('dddd, DD MMMM YYYY')
    },
    positionStickyHeader () {
      if (this.sesiCustom.length === 0) return '70px'
      if (!this.openCollapse) return '168px'
      if (this.sesiCustom.length === 1 && this.openCollapse) return '350px'
      if (this.sesiCustom.length > 1) return '440px'
      return '70px'
    },
    titleModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Tempatkan Jadwal Survey Telah Tepat?'
      }
      return ''
    },
    descModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Konsultan yang ditugaskan pada survey ini akan dihapus'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Pastikan jadwal survey yang telah kamu tentukan telah sesuai'
      }
      return ''
    },
    widthDescModalAction () {
      if (this.typeModalAction === 'delete') {
        return '200px'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return '310px'
      }
      return ''
    },
    btnModalAction () {
      if (this.typeModalAction === 'delete') {
        return 'Hapus Konsultan Survey'
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom' || this.typeModalAction === 'expand') {
        return 'Simpan Jadwal'
      }
      return ''
    }
  },
  methods: {
    formatDate (date, format) {
      if (!date) return ''
      return moment(date).format(format || 'dddd, DD MMMM YYYY')
    },
    checkIfReaching (event) {
      // Get mouse coordinates relative to the div
      const card = event.currentTarget
      const cardRect = card.getBoundingClientRect()

      const mouseX = event.clientX - cardRect.left
      const mouseY = event.clientY - cardRect.top

      const radiusWidth = 30
      const radiusHeight = 20
      const spaceFromTop = 0

      const targetLeft = (card.offsetWidth - radiusWidth) / 2
      const targetTop = spaceFromTop
      const targetRight = targetLeft + radiusWidth
      const targetBottom = targetTop + radiusHeight

      // Check if the mouse is within the target area
      this.cursorMove =
        mouseX >= targetLeft &&
        mouseX <= targetRight &&
        mouseY >= targetTop &&
        mouseY <= targetBottom
    },
    resetReaching () {
      this.idHoverCard = null
      this.consultantIdHoverCard = null
    },
    closeModalSelectConsultant () {
      this.modalSelectConsultant = false
    },
    handleSelectConsultant (consultantName) {
      if (!consultantName) {
        this.modalSelectConsultant = !this.modalSelectConsultant
      }
    },
    handleMinimizeSidebar () {
      this.minimize = !this.minimize
    },
    handlePrevNext (type) {
      const tempCurrentDate = JSON.parse(JSON.stringify(moment(this.selectedDate).toDate()))
      const currentDate = moment(this.selectedDate)

      if (type === 'prev') {
        this.selectedDate = currentDate.add(-1, 'days').toDate()
      } else if (type === 'next') {
        this.selectedDate = currentDate.add(1, 'days').toDate()
      }

      const updatedDate = moment(this.selectedDate)
      if (
        moment(tempCurrentDate).year() !== updatedDate.year() ||
        moment(tempCurrentDate).month() !== updatedDate.month()
      ) {
        this.fromPage = {
          year: updatedDate.year(),
          month: updatedDate.month() + 1 // Moment months are zero-based
        }
      }
    },
    handleHoverCard (cardId, consultantId) {
      if (!cardId) {
        this.resetReaching()
      } else {
        this.idHoverCard = cardId
        this.consultantIdHoverCard = consultantId
      }
    },
    handleCancelDeleteConsultant () {
      this.modalActionConsultant = false
      this.idHoverCard = this.tempIdHoverCard
      this.consultantIdHoverCard = this.tempConsultantIdHoverCard
    },
    handleDeleteConsultant () {
      this.modalActionConsultant = false
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempCard.id)

      // HIT API DELETE
      this.tempCard.removed = true
      setTimeout(() => {
        toastMessage('Konsultan Survey Berhasil Dihapus', 'is-dark')
        this.tempCard = null
        this.cardSurvey.splice(findIdx, 1)
      }, 500)
    },
    handleDetailConsultant () {},
    resetDraggedCard () {
      this.draggedEvent = null
      this.cursorMove = false
      this.modalActionConsultant = false
    },
    handleConfirmMoveCard () {
      const findIdx = this.sesiCustom.findIndex((item) => item.id === this.tempCard.id)
      if (findIdx > -1) this.sesiCustom.splice(findIdx, 1, { ...this.tempCard, removed: true })

      setTimeout(() => {
        if (findIdx > -1) this.sesiCustom.splice(findIdx, 1)
      }, 1000)

      this.resetDraggedCard()
    },
    handleExpandCard () {
      this.dataStretching = null
      this.tempCard = null
      this.modalActionConsultant = false
    },
    handleCancelExpand () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.dataStretching.id)
      this.cardSurvey.splice(findIdx, 1, this.dataStretching)
      this.dataStretching = null
      this.tempCard = null
      this.modalActionConsultant = false
    },
    handleCancelMoveCard () {
      const findIdx = this.cardSurvey.findIndex((item) => item.id === this.tempDraggedEvent.id)
      if (findIdx > -1) {
        if (this.typeModalAction === 'move-sesi-custom') {
          this.cardSurvey.splice(findIdx, 1)
        } else if (this.typeModalAction === 'move') {
          this.cardSurvey.splice(findIdx, 1, this.tempDraggedEvent)
        }
      }
      this.resetDraggedCard()
    },
    handleCancelModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleCancelDeleteConsultant()
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom') {
        this.handleCancelMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleCancelExpand()
      }
    },
    handleConfirmModalAction () {
      if (this.typeModalAction === 'delete') {
        this.handleDeleteConsultant()
      } else if (this.typeModalAction === 'move' || this.typeModalAction === 'move-sesi-custom') {
        this.handleConfirmMoveCard()
      } else if (this.typeModalAction === 'expand') {
        this.handleExpandCard()
      }
    },
    openDeleteConsultant (card, consultant) {
      this.typeModalAction = 'delete'
      this.tempIdHoverCard = JSON.parse(JSON.stringify(this.idHoverCard))
      this.tempConsultantIdHoverCard = JSON.parse(JSON.stringify(this.consultantIdHoverCard))

      this.resetReaching()
      this.tempCard = {
        ...card,
        consultant
      }
      this.modalActionConsultant = !this.modalActionConsultant
    },
    onDragSesiCustomStart (event, data) {
      this.draggedEvent = JSON.parse(JSON.stringify(data))
      this.typeModalAction = 'move-sesi-custom'

      // const cardElement = event.target.getBoundingClientRect()

      // const offsetX = 400
      // const offsetY = 20
      // this.cursorPosition = {
      //   x: event.clientX - offsetX,
      //   y: event.clientY - offsetY
      // }

      // this.cursorPosition = {
      //   x: event.clientX - cardElement.left,
      //   y: event.clientY - cardElement.top
      // }

      // this.updateCursorPosition(event)
      // document.addEventListener('dragover', this.updateCursorPosition)
    },
    onDragSesiCustomEnd (event) {
      this.draggedEvent = null
    },
    onDragStart (event, data, consultant) {
      this.typeModalAction = 'move'
      if (!this.cursorMove) return
      this.draggedEvent = { ...data, consultant_id: consultant.id }
      const findIdx = this.cardSurvey.findIndex(
        (item) =>
          item &&
          item.consultant_id === data.consultant_id &&
          item.start_time === data.start_time &&
          item.end_time === data.end_time
      )

      // event.dataTransfer.effectAllowed = 'move'
      setTimeout(() => {
        if (findIdx === -1) return
        this.cardSurvey.splice(findIdx, 1)
      }, 0)
    },
    onDragEnd () {
      if (this.draggedEvent) {
        this.cardSurvey.push(this.draggedEvent)
      }
    },
    onDrop (event, timeSlot, consultant) {
      if (this.draggedEvent) {
        const startIndex = this.timeSurvey.indexOf(timeSlot)
        const duration = this.typeModalAction === 'move-sesi-custom' ? 1
          : this.timeSurvey.indexOf(this.draggedEvent.end_time) -
          this.timeSurvey.indexOf(this.draggedEvent.start_time) // Calculate duration

        const newStartTime = timeSlot
        const newEndTime = this.timeSurvey[startIndex + duration]
        // Check if newEndTime is valid
        if (!newEndTime) {
          alert('Invalid time slot!')
          return
        }

        this.tempDraggedEvent = JSON.parse(JSON.stringify(this.draggedEvent))

        // Skip overlap check if dropping in original position
        if (
          timeSlot === 'empty' ||
          (this.draggedEvent.start_time === newStartTime &&
            this.draggedEvent.end_time === newEndTime &&
            this.draggedEvent.consultant_id === consultant.id)
        ) {
          this.cardSurvey.push(this.draggedEvent)
          this.draggedEvent = null
          return
        }

        // Overlap detection
        const overlappingEvent = this.cardSurvey.find((e) => {
          return (
            this.typeModalAction !== 'move-sesi-custom' &&
            e.id !== this.draggedEvent.id &&
            e.consultant_id === consultant.id &&
            ((e.start_time >= newStartTime && e.start_time < newEndTime) ||
              (e.end_time > newStartTime && e.end_time <= newEndTime) ||
              (e.start_time <= newStartTime && e.end_time >= newEndTime))
          )
        })

        if (overlappingEvent) {
          alert('This time slot is already occupied!')
          return
        }

        this.draggedEvent.start_time = newStartTime
        this.draggedEvent.end_time = newEndTime
        this.draggedEvent.consultant_id = consultant.id
        this.draggedEvent.consultant = consultant

        this.cardSurvey.push(this.draggedEvent)
        this.tempCard = this.draggedEvent

        this.resetDraggedCard()
        this.modalActionConsultant = true
      }
      document.removeEventListener('dragover', this.updateCursorPosition)
    },

    onDragEnter (event, time, consultantId) {
      // console.log(event)
    },
    onDragLeave (event) {
      // console.log(event)
    },
    onSelectConsultant (data) {
      console.log(data)
    },

    startStretching (event, data) {
      event.preventDefault()
      // console.log(event)

      this.isStretching = true
      this.dataStretching = data
      this.startMouseY = event.clientY

      document.body.style.cursor = 'ns-resize'

      window.addEventListener('mousemove', this.stretchCard)
      window.addEventListener('mouseup', this.stopStretching)
    },
    stretchCard (event) {
      if (this.isStretching) {
        event.preventDefault() // Ensure no unwanted behavior during dragging

        const mouseDiff = event.clientY - this.startMouseY

        const duration = this.timeSurvey.indexOf(this.dataStretching.end_time) -
          this.timeSurvey.indexOf(this.dataStretching.start_time)
        const cardHeight = duration * this.HEIGHT_COLUMN

        // Calculate raw height based on the mouse movement
        const rawHeight = cardHeight + mouseDiff

        // Determine the threshold for snapping (50% of rowHeight)
        const snapThreshold = this.rowHeight / 2

        // Calculate the closest row boundary with snapping
        let newHeight
        if (rawHeight > cardHeight + snapThreshold) {
          // Snap to the next row when moving downward
          newHeight = Math.ceil(rawHeight / this.rowHeight)
        } else if (rawHeight < cardHeight - snapThreshold) {
          // Snap to the previous row when moving upward
          newHeight = Math.floor(rawHeight / this.rowHeight)
        } else {
          // Stay at the current row if within the threshold
          newHeight = Math.round(cardHeight / this.rowHeight)
        }

        // Cap the height to stay within valid row boundaries
        newHeight = Math.max(1, Math.min(newHeight, this.timeSurvey.length))

        // // Update the card height
        // this.cardHeight = newHeight * this.rowHeight

        this.typeModalAction = 'expand'
        const findIdx = this.timeSurvey.findIndex((item) => item === this.dataStretching.start_time)
        this.tempCard = {
          ...this.dataStretching,
          end_time: this.timeSurvey[findIdx + newHeight]
        }
        const findIdxSurvey = this.cardSurvey.findIndex((item) => item.id === this.dataStretching.id)
        this.cardSurvey.splice(findIdxSurvey, 1, this.tempCard)
      }
    },
    stopStretching () {
      // Stop resizing
      this.isStretching = false

      // Reset the cursor style
      document.body.style.cursor = ''

      // Remove global event listeners
      window.removeEventListener('mousemove', this.stretchCard)
      window.removeEventListener('mouseup', this.stopStretching)

      if (this.tempCard.end_time !== this.dataStretching.end_time) {
        this.modalActionConsultant = true
      }
    },

    getCardsForSlot (timeSlot, consultantId) {
      return this.cardSurvey.filter(
        (item) => item && item.start_time === timeSlot && item.consultant_id === consultantId
      )
    },
    getCardStyle (event, idxTime) {
      const startIndex = this.timeSurvey.indexOf(event.start_time)
      const endIndex = this.timeSurvey.indexOf(event.end_time)
      const rowSpan = endIndex - startIndex // Calculate how many rows to span
      return idxTime === this.timeSurvey.length
        ? 0
        : `${rowSpan * this.HEIGHT_COLUMN - 3}px`
    },
    getMonitoringKonsultan () {
      this.isLoadingTable = true
      this.consultants = []
      this.cardSurvey = []
      this.sesiCustom = []

      setTimeout(() => {
        this.consultants = consultants
        this.cardSurvey = cardSurvey
        this.sesiCustom = sesiCustom
        this.isLoadingTable = false
      }, 1000)
    },
    getTimeSurvey () {
      this.timeSurvey = timeSurvey
    },
    getListConstultants () {
      this.dataKonsultan = dataKonsultan
    },
    getClient () {
      this.isLoadingClient = true
      const payload = {
        url: this.url
      }

      this.$store
        .dispatch('client/getListVendor', payload)
        .then((response) => {
          this.dataClient = response.data.data.list
          this.isLoadingClient = false
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoadingClient = false
        })
    }
  },
  mounted () {
    // this.smoothUpdate()
  },
  created () {
    this.getMonitoringKonsultan()
    this.getClient()
    this.getTimeSurvey()
    this.getListConstultants()
  },
  watch: {
    selectedDate (val) {
      this.getMonitoringKonsultan()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@/scss/_variables.scss';
.sidebar-monitoring {
  width: 20%;
  height: 90vh;
  overflow: auto;
  border-right: 4px solid $color-grey-2;
  position: relative;
  background: $color-grey-6;
  padding-top: 8px;
  transition: width 0.3s ease-in-out;

  &.minimize-sidebar {
    width: 0px;
    overflow: hidden;
  }
}

.body-monitoring {
  width: 80%;
  height: 90vh;
  overflow: auto;
  position: relative;
  transition: 0.5s width ease-in-out;

  &.filled-monitoring {
    width: 100%;
  }
}

.date-minimize-icon {
  width: 0px;
  transition: width 0.5s ease-in-out;
  overflow: hidden;

  &.expand-minimize-icon {
    width: 30px;
    margin-right: 29px;
    margin-left: 18px;

    img {
      width: 25px;
      height: 25px;
    }
  }
}

.v-calendar-wrapper-normal {
  width: 100%;
  height: 320px;
  overflow: hidden;
  background: $color-grey-6;
}

.minimize-icon {
  width: 25px;
  height: 25px;
  position: absolute;
  right: 10px;
  flex-shrink: 0;
  z-index: 2;
  background: white;
}

.header-current-date {
  padding: 17px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-top: 1px solid #eaeaea;
  background: white;
  z-index: 30;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
}

// ==================== TABLE BODY ====================

.schedule-table {
  display: grid;
  position: relative;
  transition: 0.2s opacity ease-in-out;
  opacity: 1;
  // right: 0;

  &.loading-table {
    // right: 250vw;
    opacity: 0;
  }
}

.header-table {
  display: flex;
  width: 100%;
  position: sticky;
  top: 70px;
  z-index: 30;
  background: #fafafa;
  border-top: 1px solid #e1e1e1;
  transition: top 0.3s ease-in-out;
}

.d-contents {
  display: contents;
}

.cell {
  width: 200px;
  border: 1px solid #e1e1e1;
  text-align: center;
  background: white;

  &.header {
    border: none !important;
    background: #fafafa;
    border-bottom: 1px solid #e1e1e1 !important;
    display: flex;
  }

  &.header-empty {
    background: #fafafa;
    border: none !important;
    z-index: 40;
    border-bottom: 1px solid #e1e1e1 !important;
    width: 100px;
  }

  &.time {
    position: sticky;
    left: 0;
    z-index: 10;
    border: none;
    border-right: 1px solid #e1e1e1;
    width: 100px;
    background: white;
  }

  &.drop-highlight {
    background: salmon;
  }
}

.cell[data-timeslot]:hover {
  background-color: #f0f0f0;
}

.card-events {
  color: white;
  border-radius: 20px;
  margin: 0px 0;
  position: relative;
  min-height: 40px;
  height: 100%;
  width: 100%;
  z-index: 20;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
  border: 1px solid transparent;

  &.remove-card {
    opacity: 0;
  }

  &.card-sesi-custom {
    width: 80%;
    border: 1px solid white;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 21;

    .expand-handle {
      cursor: ns-resize;
      position: absolute;
      bottom: -5px;
      left: 0;
      width: 100%;
      height: 5px;
      background: lightgray;
      opacity: 0;
    }
  }

  &.transparent-card {
    opacity: 0.7;
  }
}

.border-white-1 {
  border: 1px solid white;
}

.body-card-events {
  height: calc(100% - 35px);
  position: relative;
}

.description-card {
}

.tooltip-hover-card {
  width: 0;
  height: 0;
  position: absolute;
  top: 63%;
  left: 130px;
  box-shadow: 0px 10px 40px 0px #2525251f;
  background: white;
  z-index: 50;
  border-radius: 20px;
  transition: 0.2s all ease-in-out;
  opacity: 0;
  overflow: hidden;
}

.tooltip-hover-card.visible {
  height: 250px;
  width: 400px;
  opacity: 1;
}

.tooltip-hover-card {
  .tooltip-header {
    background: #fafafa;
    padding: 15px 20px;

    .tooltip-vendor-img {
      height: 18px;
      margin-right: 10px;

      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }

  .tooltip-date {
    background: #fff4f4;
    border-radius: 20px;
    padding: 12px;
  }
}

.drag-icon-wrapper {
  height: 30px;
}

.drag-icon {
  height: 20px;
  margin-top: 5px;
}

.cursor-move {
  cursor: move;
}

.consultan-img {
  width: 30px;
  height: 30px;
  border-radius: 15px;
  overflow: hidden;

  &img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.vendor-card-img {
  background: white;
  border-radius: 100px;
  height: 22px;
  max-width: 90%;
  padding: 4px 10px;
  overflow: hidden;
  display: inline-flex;

  img {
    height: 100%;
    widows: 100%;
    object-fit: contain;
  }
}

.time-survey {
  position: absolute;
  right: 0;
  top: -11px;

  .line {
    width: 10px;
    height: 2px;
    background: #e1e1e1;
    margin-left: 5px;
  }
}

.previous-next-arrow {
  height: 35px;
  width: 35px;
  cursor: pointer;
}

.previous-next-arrow:hover {
  background: #f0f0f0;
}

.modal-delete-consultant {
  padding: 20px;
  padding-bottom: 25px;
  background: white;
  border-radius: 20px;
  width: 35em;
}

.modal-vendor-icon {
  height: 25px;
  border-radius: 100px;
  background: white;
  padding: 4px 10px;

  img {
    height: 100%;
    object-fit: contain;
  }
}

.modal-consultant-img {
  height: 30px;
  width: 30px;
  border-radius: 15px;
  overflow: hidden;
  background: white;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.floating-card {
  position: absolute;
  top: 0;
  color: white;
  border-radius: 20px;
  height: 70px;
  width: 200px;
  z-index: 1000;
  pointer-events: none;
  box-sizing: border-box;
}

.collapse-sesi-custom-wrapper {
  width: 100%;
  padding: 17px 20px;
  border-top: 1px solid #e1e1e1;
  position: sticky;
  z-index: 30;
  top: 70px;
  left: 0;
  right: 0;
  background: white;
  transition: padding 0.5s ease-in-out, max-height 1s ease-in-out, opacity 0.7s ease-in-out;
  max-height: 450px;
  opacity: 1;

  &.minimize-sidebar {
    padding: 17px 38px;
  }

  &.removed-wrapper-sesi-custom {
    max-height: 0px;
    padding: 0;
    margin: 0;
    overflow: hidden;
    opacity: 0;
  }
}

.collapse-sesi-custom {
  background: linear-gradient(90deg, #891313 0%, #d92727 99.97%);
  border-radius: 20px;
  color: white;
  padding: 10px 20px;
}

.chevron-sesi-custom {
  position: relative;
  transition: all 0.2s ease-in-out;
  transform: rotate(270deg);
}

.chevron-sesi-custom.open {
  transform: rotate(90deg);
  transform-origin: center;
}

.body-sesi-custom {
  background: white;
  padding: 16px 20px;
  padding-bottom: 0;
  min-height: 9em;
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
  margin-top: 3px;
}

.vendor-img-sesi-custom {
  height: 30px;
  width: 90px;
  border-radius: 100px;
  background: white;
  overflow: hidden;
  padding: 5px 10px;
  margin-right: 10px;
  flex-shrink: 0;

  img {
    height: 100%;
    width: 100%;
    object-fit: contain;
  }
}

.list-survey-custom-wrapper {
  max-height: 12em;
  width: 100%;
  overflow: auto;
}

.list-sesi-custom {
  display: flex;
  background: #fafafa;
  padding: 10px;
  border-radius: 12px;
  margin-bottom: 10px;
  transition: height 0.5s ease-in-out;
  height: 82px;

  &.removed-sesi-custom {
    height: 0px;
    padding: 0px;
    margin: 0;
    overflow: hidden;
  }
}

.info-vendor-sesi-custom {
  flex: 1;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 20px;
}

.address-sesi-custom {
  flex: 2.5;
  border-right: 1px solid #e1e1e1;
  padding-right: 15px;
  margin-right: 10px;

  div {
    max-width: 35em;
  }
}

.drag-icon-sesi-custom {
  padding: 0 10px;
  height: 35px;
  align-self: center;
  // pointer-events: none;
  cursor: move;
  flex-shrink: 0;
}

.drag-div {
  width: 100px;
  height: 100px;
  background: salmon;
}
</style>

<style>
.v-calendar-wrapper-normal .vc-container {
  border: none !important;
  font-family: 'Averta' !important;
  background: #fafafa;
}

.v-calendar-wrapper-normal .vc-grid-cell {
  margin-bottom: 10px !important;
}

.v-calendar-wrapper-normal .vc-day-content {
  height: 25px !important;
  width: 25px !important;
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-weekday {
  font-size: 14px !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-start {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-end {
  height: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight.vc-highlight-base-middle {
  height: 100% !important;
  width: 100% !important;
}

.v-calendar-wrapper-normal .vc-highlight {
  width: 27px !important;
  height: 27px !important;
}

.search-left-monitoring input {
  border: 1px solid #e1e1e1;
  border-radius: 5px;
  font-size: 14px;
  margin-top: 4px;
}

.collapse-sesi-custom .collapse-trigger {
  width: 100%;
}
</style>
