<template>
  <svg
    :width="width || size"
    :height="height || size"
    viewBox="0 0 20 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle
      cx="9.80492"
      cy="10.3049"
      r="7.49047"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M15.0156 15.9043L17.9523 18.8334"
      :stroke="color"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
  props: {
    color: {
      type: String,
      default: '#868686'
    },
    size: {
      type: String,
      default: '20'
    },
    width: {
      type: String,
      default: '20'
    },
    height: {
      type: String,
      default: 'auto'
    }
  }
}
</script>
