<template>
  <DetailSkeleton v-if="isLoading" class="p-6" />
  <div class="w-100" v-else>
    <div class="container container-detail pt-5 pb-6 px-lg-6 min-h-90vh">
      <HeaderComponent
        :dataProps="dataSurvey"
        :dataPenawaran="dataPenawaran || {}"
        :isProject="false"
        :isOffering="dataPenawaran && Object.keys(dataPenawaran) && Object.keys(dataPenawaran).length > 0 ? true : false"
        :loadDataSurvey="getDetailSurvey"
        @modalSurveySelesai="handleModalSurveySelesai($event)"
        @sidebarPenawaranClient="handleSidebarPenawaranClient($event)"
        @selectedTipeOffering="handleSelectedTipeOffering($event)"
        @rejectReschedule="handleRejectReschedule"
        @updateReschedule="handleUpdateReschedule"
      />
      <div class="divider mb-4 mt-4"></div>
      <!-- Card Cancel Survey -->
       <div  style="background: #9A9A9A; position: relative;"
       v-if="dataSurvey.status === 'Survey Dibatalkan'"
       class="is-flex is-flex-direction-row is-justify-content-space-between is-align-content-center c-w-100 border-radius-20 p-5 my-5">
        <div class="is-flex is-flex-direction-row is-justify-content-flex-start is-align-items-center">
          <div class="card-icon-cancel-survey">
            <HexagonAlertIcon />
          </div>
          <div class="is-flex is-flex-direction-column is-justify-content-center is-align-items-flex-start">
            <p class="averta-bold label-26 color-white">Survey Telah Dibatalkan</p>
            <p class="averta-regular label-18 color-white">Alasan Pembatalan :  {{ dataSurvey && dataSurvey.detail && dataSurvey.detail.cancelled_reason || '-' }} </p>
          </div>
        </div>
        <div style="position: absolute; right: 20px; bottom: 20px;"
        class="is-flex is-flex-direction-row is-justify-content-flex-end is-align-items-flex-end">
          <p class="averta-bold-italic label-18 color-white">Oleh : {{dataSurvey.detail.cancelled_by}} - {{moment(dataSurvey.detail.cancelled_at).format('D MMMM YYYY')}}</p>
        </div>
       </div>
      <!-- Card Cancel Survey -->
      <div class="tabs-wrapper">
        <b-tabs v-model="activeTab">
          <b-tab-item>
            <template #header>
              <span> Detail Survey Proyek </span>
            </template>
            <TabDetailSurvey @getDetailSurvey="getDetailSurvey()" :loadDataSurvey="getDetailSurvey" />
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> Detail Penawaran </span>
            </template>
            <NewDetailPenawaran
              :isFromDetail="true"
              :activeTab="activeTab"
              :isBS="isBS"
            />
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> Riwayat Reschedule </span>
            </template>
            <TabDetailHistoryReschedule :dataHistoryReschedule="dataSurvey.reschedule_request_histories" />
          </b-tab-item>
          <b-tab-item>
            <template #header>
              <span> Catatan Proyek </span>
            </template>
            <ProjectNote class="mb-5" :surveyId="parseInt($route.params.surveyId)" />
            <PersonalNote :notes="dataSurvey.note" :surveyId="parseInt($route.params.surveyId)" :loadData="getDetailSurvey" />
          </b-tab-item>

          <b-tab-item>
            <template #header>
              <span>
                Riwayat Penolakan <b-tag rounded> {{ countReject && countReject.total_reject || 0 }} </b-tag>
              </span>
            </template>
            <TabDetailPenawaranRejected />
          </b-tab-item>

        </b-tabs>
      </div>
      <b-modal v-model="modalSurveySelesai" :can-cancel="false" :width="640">
        <div class="modal-survey-selesai">
          <div class="header-survey-selesai is-flex is-justify-content-space-between is-align-items-center">
            <div class="is-flex is-align-items-center">
              <BagIcon class="mr-3" />
              <p class="averta-bold label-20">Masukkan Data Survey</p>
            </div>
            <div class="is-clickable" @click="handleModalSurveySelesai(false)">
              <b-icon icon="close"></b-icon>
            </div>
          </div>
          <div class="body-survey-selesai p-5">
            <div class="title-body is-flex is-align-items-center mb-5">
              <img :src="require('@/assets/img/survey-selesai.png')" alt="ilustrasi" class="img-title mr-5" />
              <div>
                <p class="averta-bold">Pastikan seluruh tahapan survey telah dilakukan dan seluruh
                  lampiran telah lengkap</p>
                <!-- <p class="text-subtitle">Pastikan seluruh tahapan survey telah dilakukan dan seluruh lampiran telah lengkap</p> -->
              </div>
            </div>
            <!-- <div class="mt-6 mb-5">
              <p class="averta-bold">Upload Lampiran</p>
              <p class="text-subtitle">Lampiran akan dikirimkan ke client untuk direview</p>
            </div>
            <div class="mb-5">
              <p class="averta-bold mb-3">Foto Survey <span class="has-text-danger">*</span></p>
              <div v-if="previewImages.length > 0" class="wrapper-image-preview">
                <div v-for="(dataPreview, indexDataPreview) in previewImages" :key="indexDataPreview" class="is-flex pb-5 is-align-items-center px-3">
                  <div class="is-flex is-align-items-center">
                    <img :src="dataPreview.url" :alt="dataPreview" class="image-preview mr-3" />
                    <div class="wrapper-title-preview">
                      <p class="preview-image-name">{{ dataPreview.name }}</p>
                      <p class="preview-image-size">{{ dataPreview.size }}mb</p>
                    </div>
                  </div>
                  <div class="is-clickable" @click="removeImage(indexDataPreview)">
                    <CloseSquare />
                  </div>
                </div>
              </div>
              <label for="profile">
                <input type="file" id="profile" ref="uploadSurvey" @change="handleFileUpload" hidden multiple accept="image/*" />
                <div class="wrapper-add-file is-clickable is-flex is-justify-content-center" v-if="previewImages.length > 0">
                  <p class="averta-bold" style="color: #d9272d">+ Tambah File</p>
                </div>
                <div class="is-clickable upload-foto is-flex is-justify-content-center" v-if="previewImages.length < 1">
                  <DownloadIcon class="mr-3" />
                  <p class="white-space-nowrap">Upload File</p>
                </div>
              </label>
            </div> -->
            <div class="mb-3">
              <p class="averta-bold mb-3">Tinggalkan Catatan</p>
              <b-field>
                <b-input type="textarea" placeholder="Isi disini" v-model.trim="note"></b-input>
              </b-field>
            </div>
            <div class="notification is-danger" v-if="showNotif === true">
              {{ messageFailed }}
            </div>
            <div class="is-flex is-justify-content-end pt-3">
              <b-button class="btn-simpan" @click="simpanFotoSurvey()">
                <div class="is-flex is-align-items-center has-text-white">
                  <ArrowRightIcon color="white" class="mr-3" />
                  <span class="averta-bold">Lanjut</span>
                </div>
              </b-button>
            </div>
          </div>
        </div>
      </b-modal>

      <!-- Reschedule SM & BS Only -->
      <ModalCalendarReschedule
        :showModalRescheduleProps="showModalReschedule"
        :survey-date-from="dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_date : (dataSurvey.detail.date)"
        :survey-date-to="dataSurvey.reschedule_request ? dataSurvey.reschedule_request.date : dataSurvey.survey_schedule ? dataSurvey.survey_schedule.start_date : dataSurvey.detail.date"
        :pathAPIProps="pathAPI"
        :isFromBS="isFromBS"
        :isSMRC="isSMRC"
        :dateSelectedProps="dataSurvey.survey_date"
        :timeSessionProps="timeSession"
        :idTimeSessionProps="dataSurvey.time_sesi_id"
        @handleCloseModalReschedule="closeModalReschedule($event)"
      />
    </div>

    <!-- Konsultan -->
    <div class="btn-bottom" v-if="checkFloatingBtn && isKonsultan">
      <div class="is-flex is-justify-content-space-between w-100" v-if="checkUpBtn">
        <b-button class="btn-check-in averta-bold flex-1 btn-checkin label-sm-12" :class="{ 'mr-3': dataSurvey.can_check_in && dataSurvey.can_complete_survey }" v-if="dataSurvey.can_check_in == true" @click="() => this.$router.push(`/project/${dataSurvey.id}/checkin-pemeriksaan`)">Check-In</b-button>
        <b-button class="btn-selesai-survey flex-1 btn-survey label-sm-12" v-if="dataSurvey.can_complete_survey == true" @click="handleModalSurveySelesai(true)">Selesaikan Survey</b-button>
      </div>
      <b-button class="btn-selesai-survey adjust-padding w-100 label-sm-12" v-if="dataSurvey.can_create_offering" @click="() => this.$router.push(`/project/${dataSurvey.id}/new-offering`)">+ Buat Penawaran</b-button>
    </div>

    <!-- Site Manager -->
    <div v-if="dataPenawaran && isSM">
      <div class="btn-bottom is-hidden-tablet" v-if="dataPenawaran.can_update || dataPenawaran.can_submit">
        <ButtonApp :isSecondary="true" v-if="dataPenawaran.can_update" @click="handleUpdatePenawaran" class="mr-3 c-w-100 mb-2">
          <p class="averta-bold label-sm-12 py-2">Update Penawaran</p>
        </ButtonApp>
        <ButtonApp v-if="dataPenawaran.can_submit" @click="onProceedToPurchasing" class="c-w-100 mb-2">
          <p class="averta-bold label-sm-12 py-2">Lanjutkan ke Purchasing</p>
        </ButtonApp>
      </div>
    </div>

    <!-- Sidebar Penawaran Client BS -->
    <div v-if="dataPenawaran && isBS">
      <b-sidebar
        type="is-light"
        :fullheight="true"
        :fullwidth="true"
        :overlay="true"
        :right="true"
        v-model="sidebarPenawaranClient"
      >
      <PenawaranClient
        :show="sidebarPenawaranClient"
        :offeringId="dataPenawaran.id"
        :selectedTipeOffering="selectedTipeOffering"
        :dataHeader="dataPenawaran"
        @closeModal="handleSidebarPenawaranClient(false)"
      />
      </b-sidebar>
    </div>
    <!-- Sidebar Penawaran Client BS -->

    <b-loading :is-full-page="isFullPage" v-model="isLoadingSpinner" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import moment from 'moment'
import { mapGetters } from 'vuex'
import {
  // DownloadIcon,
  ArrowRightIcon,
  // loseSquare,
  // CloseSquare,
  // CloseSquare,
  // loseSquare,
  BagIcon,
  SpinnerIcon,
  HexagonAlertIcon
} from '@/components/icons'
import confirmOfferingImage from '../../../../../assets/img/delete-area-component.png'
import successCreateImage from '../../../../../assets/img/sunset.png'

import TabDetailSurvey from '@/components/project/TabDetailSurvey'
import TabDetailPenawaranRejected from '@/components/project/TabDetailPenawaranRejected'
import NewDetailPenawaran from '@/components/project/NewDetailPenawaran'
import DetailSkeleton from '@/components/skeleton/DetailSkeleton'
import { alertErrorMessage, isSMRC } from '@/helpers'
import TabDetailHistoryReschedule from '@/components/project/TabDetailHistoryReschedule.vue'
import HeaderComponent from '@/components/project/detail/HeaderComponent'
import ModalCalendarReschedule from '@/components/modal/ModalCalendarReschedule.vue'
import PenawaranClient from '@/views/v2/am/project/PenawaranClient'
import ProjectNote from '@/components/project/detail/ProjectNote.vue'
import PersonalNote from '@/components/project/detail/PersonalNote.vue'
import ButtonApp from '@/components/button/ButtonApp'

export default {
  name: 'DetailSurvey',
  components: {
    TabDetailSurvey,
    TabDetailPenawaranRejected,
    DetailSkeleton,
    BagIcon,
    // DownloadIcon,
    ArrowRightIcon,
    // CloseSquare,
    SpinnerIcon,
    NewDetailPenawaran,
    TabDetailHistoryReschedule,
    HeaderComponent,
    ModalCalendarReschedule,
    PenawaranClient,
    ProjectNote,
    PersonalNote,
    ButtonApp,
    HexagonAlertIcon
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl',
      user: 'auth/user'
    }),
    dateNow () {
      return moment().format('YYYY-MM-DD')
    },
    checkUpBtn () {
      if (this.dataSurvey.can_check_in === true || this.dataSurvey.can_complete_survey === true) return true
      return false
    },
    checkFloatingBtn () {
      if (this.dataSurvey.can_check_in === true || this.dataSurvey.can_complete_survey === true || this.dataSurvey.can_create_offering === true) return true
      return false
    },
    isBS () {
      if (this.roleName === 'Account Manager' || this.roleName === 'Business Manager') {
        return true
      } return false
    },
    isSM () {
      if (this.roleName === 'Site Manager') {
        return true
      } return false
    },
    isKonsultan () {
      if (this.roleName === 'Konsultan Survey') {
        return true
      } return false
    },
    isFromBS () {
      if (this.dataSurvey.can_reschedule && !this.dataSurvey.reschedule_request) return true
      return false
    },
    isSMRC () {
      return isSMRC(this.dataSurvey.vendor.code)
    },
    timeSession () {
      if (this.dataSurvey) {
        if (this.dataSurvey.start_time) {
          return this.dataSurvey.start_time
        } else if (this.dataSurvey.survey_schedule && this.dataSurvey.survey_schedule.start_time) {
          return this.dataSurvey.survey_schedule.start_time
        } else {
          return null
        }
      } else {
        return null
      }
    }
  },
  data () {
    return {
      roleName: null,
      note: null,
      messageFailed: '',

      dataSurvey: null,
      dataPenawaran: null,
      dataTimeline: null,

      isLoading: false,
      isFullPage: true,
      isLoadingSpinner: false,
      showNotif: false,
      modalSurveySelesai: false,

      // Konsultan
      fileUpload: [],
      previewUpload: [],
      selectedFiles: [],
      previewImages: [],

      // SM & BS
      activeTab: 0,
      calendarReschedule: null,
      pathAPI: '',
      showModalReschedule: false,

      // BS
      countReject: null,
      sidebarPenawaranClient: false,
      selectedTipeOffering: null,
      moment: moment
    }
  },
  methods: {
    getDetailSurvey (props = {}) {
      const { successReschedule = false } = props

      if (!successReschedule) {
        this.isLoading = true
      }

      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId
      }
      this.$store
        .dispatch('project/getDetailSurvey', payload)
        .then((response) => {
          this.dataSurvey = response.data.data
          this.$store.commit('project/resetDataDetailPenawaran')
          if (response.data.data.offering_id) {
            this.getDetailPenawaran()
            this.getDetailPenawaranRejected()

            // BS
            this.getTotalRejected()
          }

          if (this.isSM || this.isBS) {
            const fromClient = this.dataSurvey.can_reschedule && this.dataSurvey.reschedule_request
            const fromBS = this.dataSurvey.can_reschedule && !this.dataSurvey.reschedule_request
            if (fromClient) {
              this.pathAPI = 'reschedule-confirmation'
            } else if (fromBS) {
              this.pathAPI = 'reschedule'
            }

            if (successReschedule) this.activeTab = 2
            this.isLoading = false
          } else {
            this.isLoading = false
          }
        })
        .catch((error) => {
          alertErrorMessage(error)
          this.isLoading = false
        })
    },
    getDetailPenawaran () {
      const payload = {
        url: this.url,
        offering_id: this.dataSurvey.offering_id
      }
      this.$store
        .dispatch('project/getDetailPenawaran', payload)
        .then((response) => {
          const res = response.data.data
          this.dataPenawaran = res

          this.isLoading = false
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getDetailPenawaranRejected () {
      const payload = {
        url: this.url,
        offering_id: this.dataSurvey.offering_id
      }
      this.$store
        .dispatch('project/getDetailPenawaranRejected', payload)
        .then(() => {
          // Store response to vuex
        })
        .catch((error) => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    getTotalRejected () {
      const payload = {
        url: this.url,
        offering_id: this.dataSurvey.offering_id
      }

      this.$store
        .dispatch('project/getTotalRejected', payload)
        .then(response => {
          this.isLoading = false
          this.countReject = response.data.data
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
        })
    },
    fileUploaded (e) {
      e.preventDefault()
      const size = e.target.files[0].size / (1024 * 1024)
      const files = Array.from(this.$refs.uploadSurvey.files)

      files.forEach((file) => {
        this.fileUpload.push(file)
      })

      this.previewUpload.push({
        name: e.target.files[0].name,
        url: URL.createObjectURL(e.target.files[0]),
        size: size.toFixed(2)
      })
    },
    // handleFileUpload (event) {
    //   // mengambil file dari inputan
    //   this.selectedFiles = event.target.files

    //   // melakukan preview gambar
    //   for (let i = 0; i < this.selectedFiles.length; i++) {
    //     const file = this.selectedFiles[i]

    //     if (!this.validateFile(file)) {
    //       this.$buefy.toast.open({
    //         message: 'Ada file yang tidak sesuai dengan format, harap masukan file berformat gambar',
    //         type: 'is-danger'
    //       })
    //       break
    //     }

    //     const size = this.selectedFiles[i].size / (1024 * 1024)
    //     const reader = new FileReader()
    //     reader.onload = (e) => {
    //       this.previewImages.push({
    //         url: e.target.result,
    //         file: this.selectedFiles[i],
    //         name: this.selectedFiles[i].name,
    //         size: size.toFixed(2)
    //       })
    //     }
    //     reader.readAsDataURL(this.selectedFiles[i])
    //   }
    // },
    deleteUploadedFile (indexParams) {
      this.fileUpload.splice(indexParams, 1)
      const findIndexPreviewUpload = this.previewUpload.findIndex((item, index) => index === indexParams)
      this.previewUpload.splice(findIndexPreviewUpload, 1)
    },
    removeImage (index) {
      this.previewImages.splice(index, 1)
    },
    simpanFotoSurvey () {
      this.isLoadingSpinner = true

      // if (this.previewImages.length < 1) {
      //   this.isLoadingSpinner = false
      //   this.showNotif = true
      //   this.messageFailed = 'Silakan masukkan foto survey'
      //   return false
      // }

      const payload = {
        url: this.url,
        survey_id: this.$route.params.surveyId,
        // photo_survey: this.previewImages,
        note: this.note
      }

      this.$store
        .dispatch('project/completeSurvey', payload)
        .then(async (response) => {
          this.getDetailSurvey()
          this.handleModalSurveySelesai(false)
          this.isLoadingSpinner = false
          this.showNotif = false
        })
        .catch((error) => {
          console.log(error)
          this.isLoadingSpinner = false
          this.showNotif = true
          this.messageFailed = error.response.data.message[0].message || error.response.data.message
        })
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    confirmBy (role) {
      if (role === 'sm') {
        return 'Site Manager sedang mereview dan melengkapi informasi penawaran yang telah dibuat'
      } else if (role === 'am') {
        return 'Business Support sedang mereview dan melengkapi informasi penawaran yang telah dibuat'
      } else if (role === 'purchasing') {
        return 'Purchasing sedang mereview dan melengkapi informasi penawaran yang telah dibuat'
      }
    },
    moreThanTenMinutes (time) {
      const startTime = moment(time, 'HH:mm:ss')
      const now = moment()
      const diffMinutes = now.diff(startTime, 'minutes')

      if (diffMinutes >= 10) {
        return true
      } else {
        return false
      }
    },
    isBeforeTime (time) {
      const currentTime = moment()
      const compareTime = moment(time, 'HH:mm:ss')
      if (currentTime.isBefore(compareTime)) {
        return true
      } else {
        return false
      }
    },
    validateFile (file) {
      const allowedTypes = ['image/jpeg', 'image/png', 'image/jpg', 'image/JPEG', 'image/PNG', 'image/JPG']

      if (!allowedTypes.includes(file.type)) {
        return false
      }

      return true
    },
    // handleGetStatusClass (status) {
    //   return getStatusClassName(status)
    // },
    handleRejectReschedule () {
      this.$swal({
        imageUrl: require('@/assets/img/delete-area-component.png'),
        imageAlt: 'image update area component',
        width: '550px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Tolak Permintaan Reschedule Survey?</p>',
        html: '<p class="averta-regular">Pastikan kamu sudah cek kembali penawaran ini sebelum di proses</p>',
        showCancelButton: true,
        confirmButtonText: 'Tolak',
        cancelButtonText: 'Cek Kembali',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        }
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            path: 'reschedule-confirmation',
            data: {
              survey_id: this.$route.params.surveyId,
              status: 'reject'
            }
          }

          this.$store
            .dispatch('project/postReschedule', payload)
            .then(() => {
              this.getDetailSurvey({ successReschedule: true })
            })
            .catch((error) => {
              alertErrorMessage(error)
            })
        }
      })
    },
    handleUpdateReschedule () {
      this.calendarReschedule = this.dataSurvey.detail.date
      this.showModalReschedule = !this.showModalReschedule
    },
    handleModalSurveySelesai (value) {
      this.modalSurveySelesai = value
    },
    handleSidebarPenawaranClient (value) {
      this.sidebarPenawaranClient = value
    },
    closeModalReschedule (val) {
      if (val.isFinish) {
        this.getDetailSurvey({ successReschedule: true })
      }
      this.showModalReschedule = val.isOpenModal
    },
    onProceedToPurchasing () {
      this.$swal({
        imageUrl: confirmOfferingImage,
        imageAlt: 'confirm offering',
        width: '600px',
        padding: '10px 10px 30px 10px',
        title: '<p class="averta-bold label-22">Lanjutkan Penawaran Ke Purchasing?</p>',
        html: '<p class="averta-regular">Pastikan detail penawaran telah sesuai</p>',
        showCancelButton: true,
        confirmButtonText: 'Kirim ke Purchasing',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn-hapus-detail-component',
          cancelButton: 'btn-batal-detail-component'
        },
        reverseButton: true
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = {
            url: this.url,
            survey_id: this.$route.params.surveyId
          }

          this.$store
            .dispatch('projectV2/createPenawaran', payload)
            .then(() => {
              this.$swal({
                width: '600px',
                padding: '10px 10px 30px 10px',
                html: `
                  <img src="${successCreateImage}" class="img-create-penawaran mb-4" />
                  <p class="averta-bold mb-4 label-22">Penawaran Berhasil Dilanjutkan</p>
                  <p>Penawaran akan direview dan dilengkapi oleh Purchasing</p>
                `,
                showCancelButton: true,
                confirmButtonText: 'Lihat Detail',
                cancelButtonText: 'Lihat Daftar',
                customClass: {
                  confirmButton: 'btn-hapus-detail-component',
                  cancelButton: 'btn-batal-detail-component'
                }
              }).then((result) => {
                if (result.isConfirmed) {
                  this.loadDataSurvey()
                } else {
                  this.$router.push('/sm/project')
                }
              })
            })
            .catch((error) => {
              console.log(error)
              this.$buefy.toast.open({
                message: 'Gagal melanjutkan penawaran, silahkan coba lagi',
                type: 'is-danger'
              })
            })
        }
      })
    },
    handleUpdatePenawaran () {
      this.$router.push(`/sm/project/${this.dataSurvey.id}/update-offering`)
    },
    handleSelectedTipeOffering (type) {
      this.selectedTipeOffering = type
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  beforeDestroy () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  created () {
    this.getDetailSurvey()
    this.roleName = this.user.roles[0].name
    window.scrollTo(0, 0)
  }
}
</script>

<style scoped>
.text-id-proyek {
  font-size: 24px;
}

.divider {
  border-bottom: 2px dashed #e1e1e1;
}

.btn-selesai-survey {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.25%);
  border-radius: 100px;
  font-family: 'Averta-Bold';
  color: white;
  padding: 0px 28px;
}

.btn-selesai-survey.adjust-padding {
  padding: 20px 28px;
}

.btn-check-in {
  border: 2px solid #d9272d;
  color: #d9272d;
  border-radius: 20px;
}

.header-survey-selesai {
  background: #911a1e;
  padding: 24px;
  color: white;
  border-radius: 12px 12px 0px 0px;
}

.body-survey-selesai {
  background: white;
  border-radius: 0px 0px 12px 12px;
  height: auto;
  overflow-y: auto;
}

.img-title {
  width: 20%;
}

.text-subtitle {
  color: #2f2f2fbf;
}

.wrapper-add-file {
  background: #f7f7f7;
  padding-bottom: 18px;
  border-radius: 0px 0px 10px 10px;
}

.upload-foto {
  border: 2px #d9272d solid;
  color: #d9272d;
  width: 30%;
  padding: 16px;
  border-radius: 10px;
}

.upload-foto svg {
  fill: #d9272d;
}

.wrapper-image-preview {
  background: #f7f7f7;
  padding: 10px;
  border-radius: 10px 10px 0px 0px;
}

.image-preview {
  width: 20%;
  border-radius: 10px;
}

.preview-image-name {
  color: #3185fc;
  font-family: 'Averta-Bold';
  word-break: break-all;
}

.preview-image-size {
  color: #9a9a9a;
}

.btn-simpan {
  background: linear-gradient(116.12deg, #d9272d 16.15%, #eb4600 83.24%);
  border-radius: 90px;
}

.btn-bottom {
  display: none !important;
}

.flex-1 {
  flex: 1 !important;
}

.w-100 {
  width: 100%;
}

@media only screen and (max-width: 768px) {

  .body-survey-selesai {
    height: 60vh;
    overflow-y: auto;
  }
  .container {
    padding: 6px 20px !important;
  }

  .text-id-proyek {
    font-size: 18px;
  }

  .divider {
    border-bottom: none;
  }

  .btn-bottom {
    display: none !important;
  }

  .btn-checkin,
  .btn-survey {
    white-space: normal;
    height: auto;
    padding: 0.5rem;
  }

  .btn-checkin {
    border-radius: 100px;
  }
}

@media only screen and (max-width: 600px) {
  .btn-bottom {
    display: flex !important;
    position: fixed;
    bottom: 0;
    z-index: 10;
    width: 100%;
    background-color: white;
    padding: 0.8rem 1rem;
    box-shadow: 0px -3px 30px rgba(0, 0, 0, 0.07);
  }

  .tabs-wrapper {
    width: calc(100% + 40px);
    margin-left: -20px;
  }
}
.card-icon-cancel-survey{
  display: flex;
  flex-direction: column;
  width: 80px;
  min-width: 80px;
  max-width: 80px;
  height: 80px;
  min-height: 80px;
  max-height: 80px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background-color: #f5f5f5;
  margin-right: 16px;
}
</style>
