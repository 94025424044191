<!-- eslint-disable vue/no-mutating-props -->
<template>
  <div>
    <b-modal v-model="isHandleOpenModalAssignConsultant" :can-cancel="false">
      <div class="content-modal">
        <!-- Header Modals -->
        <div class="modal-header">
          <div class="flex-row-start gap-3">
            <CheckIcon />
            <p class="averta-bold label-18 color-white">Tugaskan Konsultan Survey</p>
          </div>
          <div class="flex-row-end cursor-pointer" @click="closeModal">
            <XMarkIcon />
          </div>
        </div>
        <!-- Header Modals -->

        <!-- Body Modals -->
        <div class="modal-body gap-4">
          <div class="flex-row-space-between blue-light-color p-4 c-w-100 border-radius-20">
            <div class="flex-row-start gap-3">
              <CalendarIcon />
              <p class="averta-bold label-17">Tanggal Survey</p>
            </div>

            <div class="flex-row-end gap-3">
              <p class="averta-bold label-17">{{ formatDate(dataSurvey.detail.date) }}</p>
            </div>
          </div>

          <div class="flex-row-space-between p-4 c-w-100 border-radius-20 gray-light-color">
            <div class="flex-row-start gap-3">
              <div v-if="!consultantSelected" class="flex-row-start">
                <UserBlackIcon />
              </div>
              <div v-if="consultantSelected" class="flex-row-start circle-avatar">
                <UserBlackIcon v-if="consultantSelected.image === '-'" />
                <img :src="consultantSelected.image" alt="avatar" v-else />
              </div>
              <div class="flex-column-start">
                <p v-if="!consultantSelected" class="averta-bold label-17">
                  Pilih Konsultan Survey
                </p>
                <p v-if="consultantSelected" class="averta-bold label-17">
                  {{ consultantSelected.name }}
                </p>
              </div>
            </div>
            <div class="flex-row-start gap-3">
              <ButtonApp height="30px" :isSecondary="true" @click="handleOpenModalSelectConsultant">
                <p class="averta-bold">
                  {{ consultantSelected ? 'Ubah Konsultan' : 'Pilih Konsultan' }}
                </p>
              </ButtonApp>
              <ButtonApp
                height="30px"
                :isSecondary="true"
                v-if="consultantSelected"
                @click="resetConsultant"
              >
                <div class="flex-row-center line-height-0 p-0">
                  <TrashIcon width="16px" />
                </div>
              </ButtonApp>
            </div>
          </div>

          <!-- <div class="divider-dashed-line-distance-1"></div> -->

          <!-- <div class="flex-row-start c-w-100">
            <p class="averta-bold label-17">Tentukan Jam Mulai Survey</p>
          </div>

          <div class="flex-row-start p-4 c-w-100 border-radius-20 gray-light-color">
            <div class="flex-row-start gap-3">
              <ClockCircle color="#868686" />
              <p class="averta-regular label-17">00:00:00</p>
            </div>
          </div>

          <div class="flex-row-start p-2 c-w-100 border-radius-20 red-light-color">
            <div class="flex-row-start gap-3">
              <AlertIcon />
              <p class="averta-semibold label-13 color-red">
                Pilih konsultan survey terlebih dahulu
              </p>
            </div>
          </div> -->
        </div>
        <!-- Body Modals -->

        <!-- Footer Modals -->
        <div class="modal-footer">
          <ButtonApp
            height="40px"
            :isDisabled="!consultantSelected"
            @click="handleOpenModalConfirmAssignConsultant"
          >
            <p class="averta-bold">Tugaskan Konsultan Survey</p>
          </ButtonApp>
        </div>
        <!-- Footer Modals -->
      </div>
    </b-modal>

    <ModalSelectConsultant
      ref="modalSelectConsultant"
      :isHandleOpenModalSelectConsultant="isHandleOpenModalSelectConsultant"
      :dataSurvey="dataSurvey"
      :consultants="consultants"
      @closeModal="isHandleOpenModalSelectConsultant = false"
      @selectconsultant="handleSelectedConsultant"
    />

    <ModalsConfirmAssingConsultant
      :isHandleOpenModalConfirmAssignConsultant="isHandleOpenModalConfirmAssignConsultant"
      @closeModalConfirmAssignConsultant="closeModalConfirmAssignConsultant"
      @submitAssignConsultant="submitAssignConsultant"
    />

    <b-loading :is-full-page="isFullPage" v-model="isLoading" :can-cancel="false">
      <SpinnerIcon />
    </b-loading>
  </div>
</template>

<script>
import {
  XMarkIcon,
  CheckIcon,
  CalendarIcon,
  UserBlackIcon,
  // ClockCircle,
  // AlertIcon,
  TrashIcon,
  SpinnerIcon
} from '@/components/icons'
import { mapGetters } from 'vuex'

import ButtonApp from '@/components/button/ButtonApp.vue'
import ModalsConfirmAssingConsultant from './ModalsConfirmAssingConsultant.vue'
import ModalSelectConsultant from './ModalSelectConsultant.vue'
import moment from 'moment'

export default {
  name: 'ModalAssignConsultant',
  props: {
    isHandleOpenModalAssignConsultant: {
      type: Boolean,
      default: false
    },
    dataSurvey: {
      type: Object,
      default: null
    },
    consultants: {
      type: Array,
      default: null
    }
  },
  data () {
    return {
      isHandleOpenModalSelectConsultant: false,
      isHandleOpenModalConfirmAssignConsultant: false,
      consultantSelected: null,
      isLoading: false,
      isFullPage: true
    }
  },
  computed: {
    ...mapGetters({
      url: 'setup/getUrl'
    })
  },
  components: {
    XMarkIcon,
    CheckIcon,
    ButtonApp,
    CalendarIcon,
    UserBlackIcon,
    // ClockCircle,
    // AlertIcon,
    ModalSelectConsultant,
    ModalsConfirmAssingConsultant,
    TrashIcon,
    SpinnerIcon
  },
  methods: {
    handleOpenModalConfirmAssignConsultant () {
      this.isHandleOpenModalConfirmAssignConsultant = true
    },
    closeModalConfirmAssignConsultant () {
      this.isHandleOpenModalConfirmAssignConsultant = false
    },
    closeModal () {
      this.$emit('closeModal')
    },
    formatDate (date) {
      return date ? moment(date).format('DD MMMM YYYY') : 'Tanggal Tidak Tersedia'
    },
    async submitAssignConsultant () {
      this.isLoading = true

      try {
        const payload = {
          survey_id: this.dataSurvey.id,
          homecare_project_id: this.dataSurvey.survey_homecare_id,
          worker_id: this.consultantSelected.id
        }

        const res = await this.$store.dispatch('projectV2/postAssignConsultant', {
          url: this.url,
          payload
        })

        if (res?.data?.code === 1) {
          await this.$swal({
            icon: 'success',
            title: 'Success',
            text: res.data.message
          })

          this.$buefy.toast.open({
            message: 'Konsultan Sudah di Assign'
          })

          window.location.reload()

          this.isLoading = false
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: res?.data?.message || 'An error occurred while submitting the Consltant Sudah di Assign.'
          })
        }
      } catch (error) {
        console.error('Error submitting Consltant Sudah di Assign:', error.response?.data || error.message)

        this.$swal({
          icon: 'error',
          title: 'Error',
          text: error.response?.data?.message || 'An unexpected error occurred. Please try again.'
        })
      } finally {
        this.isLoading = false
      }
    },
    handleOpenModalSelectConsultant () {
      this.isHandleOpenModalSelectConsultant = true
    },
    handleSelectedConsultant (consultant) {
      this.consultantSelected = consultant
    },
    resetConsultant () {
      this.consultantSelected = null
      if (this.$refs.modalSelectConsultant) {
        this.$refs.modalSelectConsultant.resetConsultantSelected()
      }
    }
  }
}
</script>

<style scoped>
.content-modal {
  display: flex;
  flex-direction: column;
  width: 70%;
  min-width: 70%;
  max-width: 70%;
  height: auto;
  min-height: auto;
  max-height: 650px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0;
  background: transparent;
}
.modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #8b080d;
  border-radius: 10px 10px 0 0;
}
.modal-body {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0 auto;
  padding: 2.5rem 1.5rem;
  gap: 1.5rem;
  background: #ffffff;
}
.modal-footer {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 65px;
  justify-content: flex-end;
  align-items: center;
  margin: 0 auto;
  padding: 0 1.5rem;
  background: #ffffff;
  box-shadow: 0px -3px 30px 0px #00000012;
  border-radius: 0 0 10px 10px;
}
.blue-light-color {
  background: #3185fc1a;
}
.gray-light-color {
  background: #fafafa;
}
.red-light-color {
  background: #ff00001a;
}
.circle-avatar {
  display: flex;
  flex-direction: row;
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  margin: 0;
  padding: 0;
  background: transparent;
  border-radius: 50%;
  overflow: hidden;
}
.circle-avatar > img {
  object-fit: cover;
  height: 100%;
  width: 100%;
}
</style>
