<template>
  <svg :width="size" :height="size" viewBox="0 0 53 53" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect width="53" height="53" rx="26.5" fill="#FCE5E6" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M26.4537 18.748H17.5003C15.9349 18.748 14.667 20.0925 14.667 21.7485V36.7482C14.667 38.4057 15.9349 39.7487 17.5003 39.7487H33.0837C34.6491 39.7487 35.917 38.4057 35.917 36.7482V25.769L30.3722 31.6397C29.8879 32.1575 29.2573 32.5153 28.5645 32.6654L24.7664 33.47C22.2872 33.9942 20.1027 31.6808 20.5986 29.0571L21.3579 25.0352C21.4953 24.3099 21.8325 23.6426 22.3269 23.1199L26.4537 18.748Z"
      :fill="color"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M38.5324 16.1158C38.3892 15.7532 38.1792 15.4207 37.9133 15.1355C37.6519 14.8566 37.3367 14.6335 36.9868 14.4796C36.6421 14.3282 36.2696 14.25 35.8931 14.25C35.5166 14.25 35.1442 14.3282 34.7995 14.4796C34.4496 14.6335 34.1344 14.8566 33.873 15.1355L33.0995 15.9543L37.1398 20.2326L37.9133 19.4124C38.182 19.1294 38.3923 18.7963 38.5324 18.4321C38.8242 17.6876 38.8242 16.8603 38.5324 16.1158ZM35.1381 22.3534L31.0963 18.0736L24.3289 25.242C24.229 25.3484 24.1617 25.4811 24.1348 25.6245L23.3755 29.6478C23.2763 30.172 23.7141 30.6338 24.2085 30.529L28.008 29.7257C28.1462 29.6949 28.272 29.6234 28.3692 29.5203L35.1381 22.3534Z"
      :fill="color"
    />
  </svg>
</template>

<script>
export default {
  name: 'EditStepIcon',
  props: {
    size: {
      type: String,
      default: '60'
    },
    color: {
      type: String,
      default: '#E10009'
    }
  }
}
</script>
