export const consultants = [
  {
    id: 66097,
    name: 'Tatan',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Alfamart',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 66098,
    name: 'Dadan',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Alfamart',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 66099,
    name: 'Rian Setiaji',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Alfamart',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 66100,
    name: 'Eko Supria',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 66101,
    name: 'Farhan Maulana',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 66102,
    name: 'Reza Maulana',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 66103,
    name: 'Adi Pratama',
    image_url:
      'https://png.pngtree.com/png-vector/20230918/ourmid/pngtree-happy-smile-man-png-image_10131050.png',
    vendor: {
      id: 1,
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 'jn182-8d19ja-129jds',
    name: '',
    image_url: '',
    vendor: null
  },
  {
    id: 'as091-oidnq-12893nadsk',
    name: '',
    image_url: '',
    vendor: null
  },
  {
    id: 'oisaj1-12ijnokd-1209das',
    name: '',
    image_url: '',
    vendor: null
  }
]

export const dataKonsultan = consultants.filter(item => item.name)

export const timeSurvey = [
  'empty',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30'
]

export const cardSurvey = [
  {
    id: 1,
    consultant_id: 66097,
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '11:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Kebon Jeruk',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 2,
    title: '',
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '10:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Cikokol',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 3,
    consultant_id: 66099,
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '09:30',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Alam Sutera',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 4,
    consultant_id: 66100,
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '11:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Kebon Jeruk',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 5,
    consultant_id: 66101,
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '10:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Cikokol',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 6,
    consultant_id: 66102,
    sesi: 'SH1',
    start_time: '09:00',
    end_time: '09:30',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 7,
    consultant_id: 66102,
    sesi: 'SH1',
    start_time: '10:00',
    end_time: '11:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Kebon Jeruk',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 8,
    consultant_id: 'jn182-8d19ja-129jds',
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '10:00',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 9,
    title: 'Alfamart Alam Sutera',
    consultant_id: 'as091-oidnq-12893nadsk',
    sesi: 'SQ1',
    start_time: '09:00',
    end_time: '09:30',
    date: '2024-01-28',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Summerville Project',
      background: '#B40007',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  }
]

export const sesiCustom = [
  {
    id: 'A1',
    sesi: 'custom',
    consultant_id: null,
    start_time: null,
    end_time: null,
    date: '2025-01-27',
    address: 'Jl. Boulevard Raya Gading Serpong Blok M5 No. 3 Tangerang 15810, Indonesia - Lantai 29, Unit 10',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Alfamart Kebon Jeruk',
      background: '#276ACA',
      image_url: 'https://upload.wikimedia.org/wikipedia/commons/8/86/Alfamart_logo.svg'
    }
  },
  {
    id: 'A2',
    sesi: 'custom',
    consultant_id: null,
    start_time: null,
    end_time: null,
    date: '2025-01-27',
    address: 'Jl. Boulevard Raya Gading Serpong Blok M5 No. 3 Tangerang 15810, Indonesia - Lantai 29, Unit 10',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Summerville Project',
      background: '#B40007',
      no_unit: 'MA05/012',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  },
  {
    id: 'A3',
    sesi: 'custom',
    consultant_id: null,
    start_time: null,
    end_time: null,
    date: '2025-01-27',
    address: 'Jl. Boulevard Raya Gading Serpong Blok M5 No. 3 Tangerang 15810, Indonesia - Lantai 29, Unit 10',
    vendor: {
      id: 1,
      company: 'PT. Sumber Alfaria Trijaya, Tbk',
      name: 'Summerville Project',
      background: '#B40007',
      no_unit: 'MA05/012',
      image_url: 'https://images-residence.summarecon.com/images/project/serpong-m-town/M-Town_Logo2.png'
    }
  }
]

export const responseAPI = {
  data: {
    consultants: [
      {
        id: 5701,
        name: 'Account Manager Shell AMRT',
        email: 'am.shell-amrt@email.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5780,
        name: 'Administrator',
        email: 'administrator@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5605,
        name: 'Aldi Tegar Prakoso',
        email: 'aldi.prakoso1@kanggo.id',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5618,
        name: 'AM Egar',
        email: 'halloegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 7030,
        name: 'Andi Surandi',
        email: 'andisurandi81@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6984,
        name: 'Bela Yuliana',
        email: 'belayuliana8@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image/1729049849460PROFILE.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=x5BkOVmH%2FkVgF%2BN9Ga79q5Tye3GHbN39G0BPVlEoDX0BACYZHnzj1%2BdRZUJ%2BCDJw8osqicF9qNE0WK96GCvCFf%2FuIRuV1RnWm8zzhLfrT2ZTvFhElNDYcpcmY6%2F4uZMFaayKYwao7CW%2BA5qd8n7onUXMqg4DgsC4xL2B1YoN5NtF5YBHj5APH0jk6bvODmKxjXVPdRhptDIRt3X7w1M7JzlexnTxs6mlgOZImFNO2L0xdTvMZ3Do92FocE46hCWh5jOOo9V%2Bv2oE4EB4%2Bh6t1Hx%2B1wL2sR4x3bNo3s1aLt1tn0DrtG2UQsBM5SUijL5bKrvAF0eYEUsxshiOERx8TA%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5631,
        name: 'BS Egar',
        email: 'amegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5917,
        name: 'Dante DMC',
        email: 'dante@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6796,
        name: 'Eko Mulyadi',
        email: 'ekoedit@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 7020,
        name: 'Emha Reza',
        email: 'emhareza2007@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6993,
        name: 'Fakhri Dev',
        email: 'fakhridev@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6797,
        name: 'Farah',
        email: 'farah@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6947,
        name: 'Gosman',
        email: 'gosman.santoso@kanggo.id',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image/1731643696534.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=tsGGLyuSMTnI5BSDuX%2FOLIv2yNHvegPwesXJELdDrkWT2TAU4q1D5OoAFrgfmCx%2BM59EkWxDSCo8EyzY2licWwcQxQhqqZMC3iivXLqobvvXzvShjbfQS87FrpxHjsbzv%2FIsO5km%2F50Naw69EmUGdGfnGUyQVtGwSUditrwciLZlF0%2FUC%2B6YaYiBrp6afjdkT7TMRuH6g7ZgMwn%2B69kxeri39XlAb7eAInoHBYGAjo44ubEVgBqP8HSA%2F48u5TdnBhWdMkCQNLNeyyCBpBBDJwqABq5FnrskACUVc15sJ9z033UUJw1Jryk%2F%2BFVYGPH%2FV9DvRT62BzzMHBwWam8odQ%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 7106,
        name: 'Jul',
        email: 'jul@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6905,
        name: 'Konsultan Alfa One',
        email: 'konsultan.alfa.one@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6908,
        name: 'Konsultan Alfa Three',
        email: 'konsultan.alfa.three@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image/1724386771577.jpeg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=0C3uZabLg4xt6nZOfZY%2FhaifOys04qWCp7bZpzndB1PriC64Y7Vek3g86ov2A0hzz9%2FPXblzOzbKY%2FSmi%2B56vi3Eh%2Fosp2SyGt5DrqtyITKDJpDIFUUgzewSTrcbLvGUxRJVJ8rlQqBEqPShhSkyY8B9uvDv2ddYuCWDoULpVhCOXcoJUWMxPi1%2BDIzS0Z7BO3PeQLAKSGiWpmrU%2Fk1sWDuWGKRLWWu4y5vL95pR0EurnsLAumczLyybJdn3OfufxKjw2jElI1q%2FwyshE1nh6XdoPnDH9%2FwEkquQAtYoJWvXVhbqccE6tyLsgNyh%2BdfRnwI31%2FgFHdJarDPdeER%2Blw%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6906,
        name: 'Konsultan Alfa Two',
        email: 'konsultan.alfa.two@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5767,
        name: 'Konsultan Anton',
        email: 'konsultananton@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5619,
        name: 'Konsultan Arif',
        email: 'prio.gunawan@kanggo.id',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image/1683089544139.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=K%2FhfKsF9Zy4Mie%2BKTBN9trKW%2Fqo217nWURLZr15sRaA9%2Bln6vxhWUFApWX35HrNavApRdO7SEgcDbZ5snIjjZd%2FmtHIoPP2F3L6ufDLajD1VNNYv5zH5r%2BkXKIJHqXL5mTvZ5zvV2gdYXI3pWh4QtGkcCndOXzGYHsRFWpWOkYVy0cuBXG9rZSQ7awEbXErjdjIVygGXXjJhmeEy0CUBrHGxfZ7V4ldrea7cmo72BReM0kHjOh2%2B3i0wQZIKNsVzd7hJP0qDge%2FKhE1iRQ%2F9DUokilYfJZylCEYowrXNaX6YwpsBXyurhcxYVAo6C069SFVemNOyVE9oJghN4pM3pg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5751,
        name: 'Konsultan Armin',
        email: 'konsultanarmin@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5597,
        name: 'Konsultan Delapan',
        email: 'konsultandelapan@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5606,
        name: 'Konsultan Dua',
        email: 'konsultandua@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5584,
        name: 'Konsultan Dua',
        email: 'konsultandua@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5614,
        name: 'Konsultan Egar',
        email: 'konsultanegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5586,
        name: 'Konsultan Empat',
        email: 'konsultanempat@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5593,
        name: 'Konsultan Enam',
        email: 'konsultanenam@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5645,
        name: 'Konsultan Eren',
        email: 'konsultaneren@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5874,
        name: 'Konsultan Gojo',
        email: 'konsultgojo@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5610,
        name: 'Konsultan Halloegar',
        email: 'halloegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5646,
        name: 'Konsultan Levi',
        email: 'konsultanlevi@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5592,
        name: 'Konsultan Lima',
        email: 'konsultanlima@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5712,
        name: 'Konsultan Master Egar',
        email: 'tegar.pratama@kanggo.id',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5615,
        name: 'Konsultan Narto',
        email: 'konsultannarto@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image/1670471361009.jpg?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=AdfalvnmWUqcPHZofT%2B%2FPgN0eAsHRm3hZtvxws0B%2FNHkiLmAWHS4H78vfpYT0ZP%2Bqf0khLbhMAVtXCIr2rYWpL4it5Pfiff1U5QAOW5IZRA1n%2FHIYkgGCRP8htA1UVAfBmkp330X2evCk8Vx3Rm5g4o6bDg2u6M7gZkgGwKhYVl1x8cqrfQwf6SigUoI2NFLOI3zbnVgdS2TXqpsVy7kGxXWoLbVMX%2FhKy78u9H6Lpe6VPHR0sskukkb%2FgILwlayivegIgWkuPR0Fzr5AGHIgxLx7yxwiCBdfOSw6eg6SYU2ZMVfaUk0aWu%2Fs6floAq5DsgxANLYebbnkulyDqrq0w%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5642,
        name: 'Konsultan Pedri',
        email: 'konsultanpedri@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5583,
        name: 'Konsultan Satu',
        email: 'konsultansatu@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5599,
        name: 'Konsultan Sembilan',
        email: 'konsultansembilan@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5705,
        name: 'Konsultan Spanyol',
        email: 'konsultanspanyol@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5649,
        name: 'Konsultan Survey Shell AMRT',
        email: 'consultant.shell-amrt@email.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 7024,
        name: 'Konsultan Sutrisno',
        email: 'garengcentit@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5585,
        name: 'Konsultan Tiga',
        email: 'konsultantiga@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5595,
        name: 'Konsultan Tujuh',
        email: 'konsultantujuh@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5630,
        name: 'Purchasing Egar',
        email: 'purchasingegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5683,
        name: 'Purchasing Shell AMRT',
        email: 'purchasing.shell-amrt@email.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5640,
        name: 'Site Manager Egar',
        email: 'smegar@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5675,
        name: 'Site Manager Shell AMRT',
        email: 'sm.shell-amrt@email.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5681,
        name: 'Test - SM Shell',
        email: 'dodi@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 7021,
        name: 'Test Konsultan 1',
        email: 'egardeveloper@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 5897,
        name: 'User testing 1',
        email: 'amgemscool1@gmail.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 6986,
        name: 'Worker 1',
        email: 'palawo8868@skrank.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      },
      {
        id: 0,
        name: 'Worker 1',
        email: 'palawo8868@skrank.com',
        image: 'https://storage.googleapis.com/kang-go.appspot.com/profile_image%2Fdefault-user.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446997800&Signature=u8OyA9ZyPrPtzzPokbdxLvGA%2B%2F0X6dcqJ%2BfbYVzTH7gcsqwyMFldNYEFIpm98ki1lhWZ7P6%2BkYzUNV2TsuYDU0xZYfhE7E6kQPK%2F5F2I2znHUEnuSMi8x3VgrX0IuOE%2BAN4UudH0bJhy1MuLetuVFIMzIJu5X0so5wc5EfiEs2xogMrGjzpVWBY8tO6xPHdMwjb3ciTWpDqztFMIUcJf3zQ3meEiaF40vTMJW%2F4wu7GmBgfJAUUDCzLvgxk45yTTQCxAEg53tuLqP7yd8lYdhtuS%2BXMEGumCUviUdWrsMMGKLgGm5HSfqqypKa4rv4aMP902YpuqAS8DD7%2F56A4vxg%3D%3D',
        skill_id: 146,
        skill_name: 'Konsultan Survey B2B'
      }
    ],
    timeslots: [
      '08:00',
      '08:30',
      '09:00',
      '09:30',
      '10:00',
      '10:30',
      '11:00',
      '11:30',
      '12:00',
      '12:30',
      '13:00',
      '13:30',
      '14:00',
      '14:30',
      '15:00',
      '15:30',
      '16:00',
      '16:30',
      '17:00'
    ],
    survey_schedules: [
      {
        id: 661,
        survey_id: 1127,
        start_date: '2025-01-14',
        start_time: '09:00:00',
        end_time: '10:00:00',
        vendor_id: 1,
        branch_id: 48,
        consultant_id: 6906,
        branch_name: 'Paradise',
        vendor_name: 'Alfamart',
        vendor_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1722224665578_dpEsIWgTne.png_1722224665578.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=d3qsdhFHrzTdiVxvAXgyvXGuQLTW3iwYx2vVxMHM7EQkz%2BrflKA2aM97EoqQrDgkiq2AQHiGK5M3KrzQ19nQl6WnV94I%2F0O4vwRVNj1E01PE67ZfBAOuEcJDGZnnHZgqXdIYOUrkmbmXx2vWokAxNWRkkmfOn8JsoxNVYiG%2B2l32JSkz9rWkOaLmGHQf%2BVKMrliS8fGvVxxfCoqul%2FF0%2BiyOQW08XnVxqB7wndIl9TMJNy1JB8zH1v2GFAS5i6EswOqa6naXBpmf9GnQn0uRhEz8U4Hu3zcOr%2F3WvUBNBpDcNqOrPRsHVHWrwwn%2BEp0r9M7MHRbvhfejPbe76HCIFA%3D%3D'
      },
      {
        id: 659,
        survey_id: 1124,
        start_date: '2025-01-14',
        start_time: '15:00:00',
        end_time: '17:00:00',
        vendor_id: 73,
        branch_id: 411,
        consultant_id: 6984,
        branch_name: 'Kelapa Gading M-Town Signature',
        branch_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1729042311801_LGmZdOs6sz.png_1729042311801.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=kX5aEEcmPxu3rT29cVU74cCa0DM31iDGAhSsHDmxJPL2UkjVhUkDTbhR4fnPZWGXq%2BVdj9LEQm%2FHMYx4tFR5CfYpAKF3wjvx5XyREmawsSwWzs8eYGMldrZ7WmhjyGyIMJm1Guije55g%2BsV8ptwf0sN8N%2BdbStucQnjjs8kxT0MG7hcuFrRCF%2Fb6ADIHKbARK6e6hOdEWUYgKGM5KOOPbTnjioKeM1hBtox58bQ9HfnSXWTpAAk%2FAVgrp8ovY%2Ff4ueFARRLZFUsY%2FTEg1DQAWvj2vfwAAXJ7lnY%2FLdzKfam14IXHXpwB8XPfqfCo84XjLARvW6m6gl0g1trKXG1niw%3D%3D',
        vendor_name: 'Summerville Property Managements',
        vendor_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1731054997551_pqoHHmk2EX.png_1731054997551.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=NZobDaHK2Jjitr46aWvQdRdOzSQE%2B7kUNksip53f9QJPMeu1m5CUnU8Blk6AN%2FIm6d5cQvZfeQUAEgQP344xEcCcXvkbdki9Uc3O%2F6qa3iwBZFnGro8xOA6mVOyFq4PVrWqquRaIjhdmVA9qtXvOIeq4iRK%2FpQL%2Bg1Uz4SG0ofwwBLm6qEVYQHfQ5AXYWFkDha2%2Fuys6HJIxNQjhOau7XtA0KBs0WwoE4IAiaeiNb7T%2B8dDNQ%2BPEUH%2BWkrljWrPSyRtclPxY658oSyk6ma1lVHR4Ibb3q%2BHzbIRV%2B1i8axkphrKJr5NFORDTEGs2MLid0yNqZ1YrOoF%2FMEp%2BDWcV5w%3D%3D'
      },
      {
        id: 657,
        survey_id: 1121,
        start_date: '2025-01-14',
        start_time: '11:00:00',
        end_time: '13:00:00',
        vendor_id: 73,
        branch_id: 429,
        consultant_id: 0,
        branch_name: 'Bekasi M-Town Residence 4',
        branch_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-shell/branch/cropped-image_1730948608727_Y4sa4wrx4A.png_1730948608727.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=mfitGo6fVkySr%2FEfQKXnPIBqAsf1DhoEUTk8QHkc%2FiAKL%2FlVO0o2UTEw6YLeCpzFNhAz4poiyn5iGrwxWzqUD5ZsybqNZJ3S3YmYqknip2%2BkH15xOSAkDPy5ReDoXPGEo6yVTxMw4FT%2BaNIp7XzI64CYpZMvN%2Bo2QGo%2B2ZHV%2FYS1%2BisBpFeDIXLF%2Fp0QPP4YRferbIX6mNnawQNrheIZWV0BZ0GUdjtR3FgltqJ2kVpxmADaDP5Nya2lfp1rDX6ccpvhiWp0rNh2pGzSkuqYUg3O1NakoYJwA67jKA6syayyczbrL9cOjYn8kCAnKsXR3aF%2FeMOhnqLB6zwbK0CZFA%3D%3D',
        vendor_name: 'Summerville Property Managements',
        vendor_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1731054997551_pqoHHmk2EX.png_1731054997551.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=NZobDaHK2Jjitr46aWvQdRdOzSQE%2B7kUNksip53f9QJPMeu1m5CUnU8Blk6AN%2FIm6d5cQvZfeQUAEgQP344xEcCcXvkbdki9Uc3O%2F6qa3iwBZFnGro8xOA6mVOyFq4PVrWqquRaIjhdmVA9qtXvOIeq4iRK%2FpQL%2Bg1Uz4SG0ofwwBLm6qEVYQHfQ5AXYWFkDha2%2Fuys6HJIxNQjhOau7XtA0KBs0WwoE4IAiaeiNb7T%2B8dDNQ%2BPEUH%2BWkrljWrPSyRtclPxY658oSyk6ma1lVHR4Ibb3q%2BHzbIRV%2B1i8axkphrKJr5NFORDTEGs2MLid0yNqZ1YrOoF%2FMEp%2BDWcV5w%3D%3D'
      },
      {
        id: 658,
        survey_id: 1123,
        start_date: '2025-01-14',
        start_time: '15:00:00',
        end_time: '17:00:00',
        vendor_id: 73,
        branch_id: 411,
        consultant_id: 0,
        branch_name: 'Kelapa Gading M-Town Signature',
        branch_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1729042311801_LGmZdOs6sz.png_1729042311801.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=kX5aEEcmPxu3rT29cVU74cCa0DM31iDGAhSsHDmxJPL2UkjVhUkDTbhR4fnPZWGXq%2BVdj9LEQm%2FHMYx4tFR5CfYpAKF3wjvx5XyREmawsSwWzs8eYGMldrZ7WmhjyGyIMJm1Guije55g%2BsV8ptwf0sN8N%2BdbStucQnjjs8kxT0MG7hcuFrRCF%2Fb6ADIHKbARK6e6hOdEWUYgKGM5KOOPbTnjioKeM1hBtox58bQ9HfnSXWTpAAk%2FAVgrp8ovY%2Ff4ueFARRLZFUsY%2FTEg1DQAWvj2vfwAAXJ7lnY%2FLdzKfam14IXHXpwB8XPfqfCo84XjLARvW6m6gl0g1trKXG1niw%3D%3D',
        vendor_name: 'Summerville Property Managements',
        vendor_logo: 'https://storage.googleapis.com/kang-go.appspot.com/b2b-client/client/cropped-image_1731054997551_pqoHHmk2EX.png_1731054997551.png?GoogleAccessId=firebase-adminsdk-bnrw8%40kang-go.iam.gserviceaccount.com&Expires=16446992400&Signature=NZobDaHK2Jjitr46aWvQdRdOzSQE%2B7kUNksip53f9QJPMeu1m5CUnU8Blk6AN%2FIm6d5cQvZfeQUAEgQP344xEcCcXvkbdki9Uc3O%2F6qa3iwBZFnGro8xOA6mVOyFq4PVrWqquRaIjhdmVA9qtXvOIeq4iRK%2FpQL%2Bg1Uz4SG0ofwwBLm6qEVYQHfQ5AXYWFkDha2%2Fuys6HJIxNQjhOau7XtA0KBs0WwoE4IAiaeiNb7T%2B8dDNQ%2BPEUH%2BWkrljWrPSyRtclPxY658oSyk6ma1lVHR4Ibb3q%2BHzbIRV%2B1i8axkphrKJr5NFORDTEGs2MLid0yNqZ1YrOoF%2FMEp%2BDWcV5w%3D%3D'
      }
    ]
  }
}
