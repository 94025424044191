<template>
    <div class="card p-4 is-flex is-justify-content-space-between is-align-items-center flex-sm-column">
        <div class="is-flex is-align-items-center d-sm-block">
            <div class="is-flex is-align-items-center mb-3 mr-3">
                <img src="../../assets/img/penawaran-pending.png" alt="penawaran-pending" class="mr-3">
            </div>
            <div class="desc-wrapper">
                <p class="averta-bold is-size-5">Lengkapi Harga Tukang</p>
                <p class="desc">Harga tukang akan dimasukan ke dalam penawaran untuk client. Butuh bantuan?
                    <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Purchasing</a> |
                    <a href="#" class="averta-bold"><b-icon icon="whatsapp" custom-size="default" />Konsultan</a>
                </p>
            </div>
        </div>
        <div class="w-sm-100">
            <b-button class="btn-penawaran px-5 averta-bold mt-sm-2 w-sm-100" @click="lengkapiPenawaran()">+ Lengkapi Penawaran</b-button>
        </div>
    </div>
</template>

<script>
export default {
  name: 'CardLengkapiPenawaranAM',
  props: {
    offeringId: {
      type: Number
    },
    dataHeader: {
      type: Object,
      default: () => {}
    }
  },
  methods: {
    lengkapiPenawaran () {
      localStorage.removeItem('stepData')
      this.$router.push(`/am/project/survey/${this.$route.params.surveyId}/${this.offeringId}/complete-offering${this.dataHeader.vendor.id ? `?source=${this.dataHeader.vendor.id}` : ''} `)
    }
  }
}
</script>

<style scoped>
.card {
    background: rgba(49, 133, 252, 0.1);
    border: 1px solid #3185FC;
    border-radius: 12px;
}

.desc-wrapper .desc {
    color: #4A4A4A;
}

.btn-penawaran {
    background: linear-gradient(116.12deg, #D9272D 16.15%, #EB4600 83.25%);
    border-radius: 100px;
    color: white;
}

@media only screen and (max-width: 600px) {
    .flex-sm-column {
        flex-direction: column !important;
    }

    .mt-sm-2 {
        margin-top: 1rem !important;
    }

    .w-sm-100 {
        width: 100%;
    }

    .d-sm-block {
        display: block !important;
    }
}
</style>
