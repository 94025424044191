<template>
  <div class="container-wrapper min-h-80vh">
    <div v-if="isLoading" class="loading-wrapper">
      <b-loading :is-full-page="false" v-model="isLoading" :can-cancel="false">
        <SpinnerIcon />
      </b-loading>
    </div>
    <div v-else>

      <div class="button-wrapper">
        <ButtonApp width="195px" height="46px" :is-bold="true" :hover-enabled="false" @click="downloadOption">
          <b-icon icon="download" size="is-small" />
          {{isLoadingSpinner ? 'Mengunduh...' : 'Unduh File'}}
        </ButtonApp>

        <div class="option-download">
          <div
            class="button-option-download mb-2 pdf"
            :class="[
              isLoadingPDF ? 'is-unselectable' : 'is-clickable',
              {
                'success': statusDownloadPDF === 'success',
                'error': statusDownloadPDF === 'error'
              }
            ]"
            @click="downloadPDF"
          >
            <img :src="require(`@/assets/icons/${statusDownloadPDF ? 'pdf-white-used' : 'pdf-pink-used'}.svg`)" alt="">
            <div
              class="text-unduh-pdf"
              :class="[{
                'downloading': isLoadingPDF,
                'success': statusDownloadPDF === 'success',
                'error': statusDownloadPDF === 'error'
              }]"
            >
              <p class="ml-2 label-14 averta-bold">Unduh PDF</p>
              <p class="ml-2 label-14 averta-bold">Mengunduh...</p>
              <p class="ml-2 label-14 averta-bold">{{statusDownloadPDF === 'success' ? 'Unduh Selesai' : 'Gagal Diunduh'}}</p>
            </div>
          </div>
          <div
            class="button-option-download excel"
            :class="[
              isLoadingExcel || isLoadingPDF ? 'is-unselectable' : 'is-clickable',
              {
                'success': statusDownloadExcel === 'success',
                'error': statusDownloadExcel === 'error'
              }
            ]"
            @click="generateExcel"
          >
            <img :src="require(`@/assets/icons/${statusDownloadExcel ? 'excel-white-used' : 'excel-green-used'}.svg`)" alt="">
            <div
              class="text-unduh-pdf"
              :class="[{
                'downloading': isLoadingExcel,
                'success': statusDownloadExcel === 'success',
                'error': statusDownloadExcel === 'error'
              }]"
            >
              <p class="ml-2 label-14 averta-bold">Unduh Excel</p>
              <p class="ml-2 label-14 averta-bold">Mengunduh...</p>
              <p class="ml-2 label-14 averta-bold">{{statusDownloadExcel === 'success' ? 'Unduh Selesai' : 'Gagal Diunduh'}}</p>
            </div>
          </div>
        </div>

      </div>
      <div class="page" ref="document" v-for="dataPage, indexPage in paginatedList" :key="`page-${indexPage}`">
        <div class="p-3" :class="`page-${indexPage + 1}`">
          <div class="height-body-pdf">
            <div v-if="indexPage === 0" class="navbar-followup">
              <div class="logo-img has-text-right">
                <div v-if="vendor && vendor.vendor_logo" class="flex-center c-h-100">
                  <img src="@/assets/img/kanggox.png" alt="logo-kanggo" />
                  <img :src="vendor.vendor_logo" alt="partner" crossorigin="anonymous" />
                </div>
                <img v-else src="@/assets/img/logo-kanggo-2.png" alt="logo-kanggo" />
              </div>
              <div class="current-date-wrapper">
                <p class="color-black-primary">Tanggal :</p>
                <p class="averta-bold label-12 ml-2">{{currentDate}}</p>
              </div>
            </div>
            <div class="card card-border p-4">
              <div class="averta-bold mb-0 is-flex">
                <div class="has-text-centered number-followup">
                  <p>No</p>
                </div>
                <div class="has-text-centered id-survey-followup">
                  <p>ID Survey</p>
                </div>
                <div class="has-text-centered store-followup">
                  <p>Toko/Outlet</p>
                </div>
                <div class="has-text-centered status-followup">
                  <p>Status</p>
                </div>
                <div class="has-text-centered date-offering-followup">
                  <p>Tgl Penawaran Terkirim</p>
                </div>
                <div class="has-text-right contract-value-followup">
                  <p>Nilai Kontrak</p>
                  <p class="label-10 averta-regular">(Inc. Ppn)</p>
                </div>
              </div>

              <div class="divider-page mb-3 mt-2"></div>

              <div class="mb-3 is-flex" v-for="dataItem, indexItem in dataPage.list" :key="`item-${indexItem}`">
                <div class="has-text-right number-followup">
                  <p>{{ numberList(dataItem.no) }}.</p>
                </div>
                <div class="has-text-right id-survey-followup px-3">
                  <p>{{dataItem.survey_id}}</p>
                </div>
                <div class="store-followup">
                  <p>{{dataItem.store_name}}<span v-if="dataItem.unit_name"> - {{dataItem.unit_name}}</span></p>
                </div>
                <div class="has-text-centered averta-bold status-followup">
                  <p>{{dataItem.status}}</p>
                </div>
                <div class="has-text-centered date-offering-followup">
                  <p>{{dataItem.offering_date}}</p>
                </div>
                <div class="contract-value-followup is-flex is-justify-content-space-between">
                  <p>Rp</p>
                  <p>{{ formatThousand(dataItem.contract_value) }}</p>
                </div>
              </div>

              <div v-if="indexPage + 1 === paginatedList.length">
                <div class="divider-page mb-3 mt-2"></div>
                <div class="is-flex is-justify-content-flex-end">
                  <div class="is-flex averta-bold">
                    <p>Total Nilai Kontrak</p>
                    <div class="is-flex color-red ml-5 is-justify-content-space-between total-contract-value">
                      <p class="mr-5">Rp</p>
                      <p>{{formatThousand(totalContractValue)}}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="footer-followup">
            <FooterSection :pagePosition="`${ indexPage + 1 }/${ paginatedList.length }`" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { alertErrorMessage, formatCurrency, userMenuPermissions } from '@/helpers'
import { SpinnerIcon } from '@/components/icons'
import FooterSection from './components/FooterSection.vue'

export default {
  name: 'FollowUpDownload',
  components: {
    SpinnerIcon,
    FooterSection,
    ButtonApp: () => import('@/components/button/ButtonApp.vue')
  },
  data () {
    return {
      isLoading: false,
      isLoadingSpinner: false,
      isLoadingPDF: false,
      isLoadingExcel: false,
      statusDownloadPDF: '',
      statusDownloadExcel: '',

      url: process.env.VUE_APP_SHELL_URL_API,
      vendor: null,
      dataFollowUp: [],
      totalContractValue: 0
    }
  },
  computed: {
    currentDate () {
      return moment().format('DD MMMM YYYY')
    },
    paginatedList () {
      const INIT_START = 915 - 93
      const INIT_MIDLE = 1020 - 93
      const WIDTH_LIST = 170

      const pages = []
      let currentPage = []
      let currentHeight = 0

      let countNumber = 0
      this.dataFollowUp.forEach((itemList, indexList) => {
        const limitPageHeight = pages.length === 0 ? INIT_START : INIT_MIDLE

        countNumber++
        itemList.no = countNumber

        // CLONE LIST
        const divList = document.createElement('div')
        const pList = document.createElement('p')

        divList.style.width = `${WIDTH_LIST}px`
        pList.className = 'averta-regular'
        pList.textContent = `${itemList.store_name}${itemList.unit_name ? ` - ${itemList.unit_name}` : ''}`
        divList.appendChild(pList)
        document.body.appendChild(divList)

        const heightList = divList.clientHeight + 12
        if (currentHeight + heightList > limitPageHeight) {
          pages.push({ list: currentPage })
          currentHeight = heightList
          currentPage = []
        }

        currentPage.push(itemList)

        currentHeight += heightList
        document.body.removeChild(divList)
      })

      if (currentPage.length) {
        pages.push({ list: currentPage })
      }

      return pages
    }
  },
  methods: {
    formatThousand (x) {
      return formatCurrency(x, true)
    },
    convertDate (date) {
      return moment(date).format('DD MMMM YYYY')
    },
    generateExcel () {
      this.getDataFollowUp({ excel: true })
    },
    getDataFollowUp (props) {
      if (props && props.excel) {
        this.isLoadingExcel = true
      } else {
        this.isLoading = true
      }

      const {
        search,
        vendor,
        status,
        startDate,
        endDate,
        orderBy,
        order
      } = this.$route.query

      const payload = {
        url: this.url,
        isExport: true,
        search,
        vendor,
        status,
        startDate,
        endDate,
        orderBy,
        order
      }

      if (props && props.excel) {
        payload.excel = true
      }

      this.$store
        .dispatch('report/getListReportFollowUp', payload)
        .then(response => {
          const result = response.data.data

          if (props && props.excel) {
            const url = `${process.env.VUE_APP_SHELL_URL_SOCKET}/${result.path}`
            window.location.href = url

            this.statusDownloadExcel = 'success'
            setTimeout(() => {
              this.statusDownloadExcel = ''
              this.isLoadingExcel = false
            }, 3000)
          } else {
            this.dataFollowUp = result.list
            this.vendor = result.vendor
            this.totalContractValue = result.total_contract_value
          }

          this.isLoading = false
        })
        .catch(error => {
          this.isLoading = false
          alertErrorMessage(error)
          if (props && props.excel) {
            this.statusDownloadExcel = 'error'
            setTimeout(() => {
              this.statusDownloadExcel = ''
              this.isLoadingExcel = false
            }, 3000)
          }
        })
    },
    async downloadPDF () {
      if (this.isLoadingSpinner || this.isLoadingPDF || this.isLoadingExcel) return
      this.isLoadingSpinner = true
      this.isLoadingPDF = true
      // eslint-disable-next-line new-cap
      const pdf = new jsPDF('p', 'mm', 'a4') // Create A4-sized PDF

      for (let index = 0; index < this.paginatedList.length; index++) {
        if (index > 0) {
          pdf.addPage() // Add a new page for each item after the first one
        }
        const selector = `.page-${index + 1}`

        // Capture content and add it to the PDF
        await this.captureContent(selector, pdf)
      }

      moment.locale('id')
      // Save the PDF
      const filename = `${moment().format('DDMMYYYY')}_Kanggo${this.vendor && this.vendor.vendor_name ? `x${this.vendor.vendor_name}` : ''}.pdf`
      pdf.save(filename)
      this.isLoadingSpinner = false

      this.statusDownloadPDF = 'success'
      setTimeout(() => {
        this.statusDownloadPDF = ''
        this.isLoadingPDF = false
      }, 3000)
    },
    async captureContent (selector, pdf) {
      const element = document.querySelector(selector)
      return html2canvas(element, {
        useCORS: true,
        scale: 2
      })
        .then((canvas) => {
          const imgWidth = 208
          const imgHeight = (canvas.height * imgWidth) / canvas.width

          const imageData = canvas.toDataURL('image/jpeg')
          pdf.addImage(imageData, 'JPEG', 0.9, 0, imgWidth, imgHeight, '', 'NONE')
        })
        .catch((error) => {
          this.statusDownloadPDF = 'error'
          setTimeout(() => {
            this.statusDownloadPDF = ''
            this.isLoadingPDF = false
          }, 3000)
          console.error('Error capturing content:', error)
        })
    },
    numberList (value) {
      return formatCurrency(value, true)
    },
    downloadOption (props) {
      const optionDownload = document.querySelector('.option-download')

      if (props && props.remove) {
        optionDownload.classList.remove('visible')
      } else if (optionDownload) {
        optionDownload.classList.toggle('visible')
      }
    }
  },
  created () {
    this.getDataFollowUp()
    if (!userMenuPermissions('MN_RPRT')) {
      this.$router.push({ path: '/' })
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/scss/_variables.scss';

.container-wrapper {
  margin-top: 5vh;
  width: 100%;
}

.button-wrapper {
  width: 210mm;
  display: flex;
  justify-content: flex-end;
  margin: auto;
  margin-top: 2em;
  margin-bottom: 2em;
  position: relative;
}

.page {
  position: relative;
  width: 210mm;
  height: 297mm;
  padding: 0;
  margin: 0 auto;
  display: block;
  margin-bottom: 40px;
  border: 1px solid $color-dark-grey-2;
}

.navbar-followup {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 0.5em;
  margin-bottom: 2em;
}

.logo-img {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 35px;
}

.logo-img img {
  height: 100%;
  object-fit: contain;
}

.current-date-wrapper {
  display: flex;
  align-items: center;
  background: $color-grey-3;
  border: 2px solid $color-dark-grey-2;
  padding: 5px 12px;
  border-radius: 100px;
}

.divider-page {
  border-bottom: 1px solid $color-dark-grey-2;
}

.card-border {
  border-radius: 20px !important;
  border: 1px solid $color-dark-grey-2;
}

.number-followup {
  width: 5%;
  padding: 0 6px;
}

.id-survey-followup {
  width: 11%;
}

.store-followup {
  width: 26%;
  padding: 0 6px;
}

.status-followup {
  width: 23%;
}

.date-offering-followup {
  width: 20%;
}

.contract-value-followup {
  width: 15%;
}

.footer-followup {
  margin-top: -5px;
}

.total-contract-value {
  min-width: 110px;
}

.option-download {
  position: absolute;
  top: 60px;
  right: 0;
  height: 0;
  overflow: hidden;
  opacity: 0;
  transform: translateY(-10px);
  transition: height 0.3s ease, opacity 0.3s ease, transform 0.3s ease;
  padding: 0 10px;
  margin: 0 -10px;
  z-index: 3;
}

.option-download.visible {
  height: 125px;
  opacity: 1;
  transform: translateY(0);
}

.button-option-download {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 10px 20px;
  background: white;
  box-shadow: 0px 5px 8px 0px #2525250D;
  transition: background 0.2s ease, top 0.4s cubic-bezier(0.42, 0, 0, 0.92);
  position: relative;
  top: 0;
}

.button-option-download.excel.is-unselectable {
  top: -60px;
}

.button-option-download.success {
  background: #10BD41;
  pointer-events: none;
}

.button-option-download.error {
  background: #FF0000;
  pointer-events: none;
}

.button-option-download.pdf.is-unselectable {
  z-index: 2;
}

.button-option-download.pdf:hover {
  background: #FCE5E6;
}

.button-option-download.excel:hover {
  background: #ECFFEA;
}

.icon-download-wrapper {
  position: relative;
  top: 1px;
  left: 2px;
}

.text-unduh-pdf {
  height: 30px;
  overflow: hidden;
}

.text-unduh-pdf p {
  position: relative;
  top: 4px;
  transition: top 0.4s cubic-bezier(0.42, 0, 0, 0.92);
  margin-bottom: 5px;
}

.text-unduh-pdf.downloading p {
  top: -22px;
}

.text-unduh-pdf.success p, .text-unduh-pdf.error p {
  top: -48px;
  color: white;
}
</style>
